export default function ProductsIllustration({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 395 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4011_20345)">
        <rect x="16" y="8" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect
          x="28.6523"
          y="20.6523"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect
          x="28.6523"
          y="33.3047"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect x="28.6523" y="54.3918" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M38.6708 63.9856C38.9783 64.0405 39.2391 64.2025 39.4533 64.4716C39.6675 64.7407 39.7745 65.0455 39.7745 65.3859C39.7745 65.677 39.6977 65.9406 39.5439 66.1767C39.3956 66.4073 39.1787 66.5913 38.8932 66.7286C38.6076 66.8604 38.2754 66.9263 37.8965 66.9263H35.6066V61.2015H37.7894C38.1793 61.2015 38.5143 61.2674 38.7943 61.3992C39.0744 61.5309 39.2858 61.7094 39.4286 61.9346C39.5714 62.1542 39.6427 62.4013 39.6427 62.6759C39.6427 63.0054 39.5549 63.28 39.3792 63.4996C39.2034 63.7193 38.9673 63.8813 38.6708 63.9856ZM36.5456 63.6067H37.707C38.0145 63.6067 38.2534 63.5381 38.4237 63.4008C38.5994 63.258 38.6872 63.0548 38.6872 62.7912C38.6872 62.5331 38.5994 62.3327 38.4237 62.1899C38.2534 62.0417 38.0145 61.9675 37.707 61.9675H36.5456V63.6067ZM37.8141 66.1602C38.1326 66.1602 38.3825 66.0833 38.5637 65.9296C38.7449 65.7758 38.8355 65.5617 38.8355 65.2871C38.8355 65.007 38.7394 64.7846 38.5472 64.6199C38.355 64.4551 38.0997 64.3728 37.7812 64.3728H36.5456V66.1602H37.8141ZM44.9053 62.3876V66.9263H43.9663V66.3909C43.818 66.5776 43.6231 66.7258 43.3814 66.8357C43.1453 66.94 42.8927 66.9922 42.6236 66.9922C42.2667 66.9922 41.9454 66.918 41.6599 66.7698C41.3798 66.6215 41.1574 66.4018 40.9927 66.1108C40.8334 65.8197 40.7538 65.4683 40.7538 65.0564V62.3876H41.6846V64.9164C41.6846 65.3228 41.7862 65.6358 41.9894 65.8554C42.1925 66.0696 42.4699 66.1767 42.8213 66.1767C43.1728 66.1767 43.4501 66.0696 43.6533 65.8554C43.8619 65.6358 43.9663 65.3228 43.9663 64.9164V62.3876H44.9053ZM50.2299 62.3876L47.4458 69.0597H46.4738L47.3963 66.8521L45.6089 62.3876H46.655L47.9317 65.8472L49.2579 62.3876H50.2299Z"
          fill="white"
        />
        <rect
          x="161.889"
          y="20.6523"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#5FBB50"
          fillOpacity="0.16"
        />
        <path
          d="M182.193 31.3652L175.427 34.371C174.732 34.6803 174.277 35.3762 174.277 36.1397V40.6822C174.277 46.0463 177.989 51.0624 182.976 52.2801C187.963 51.0624 191.674 46.0463 191.674 40.6822V36.1397C191.674 35.3762 191.22 34.6803 190.524 34.371L183.759 31.3652C183.266 31.1429 182.686 31.1429 182.193 31.3652ZM182.976 41.639H189.741C189.229 45.621 186.571 49.168 182.976 50.2795V41.6487H176.21V36.1397L182.976 33.1339V41.639Z"
          fill="#5FBB50"
        />
      </g>
      <g filter="url(#filter1_d_4011_20345)">
        <rect x="225.268" y="8" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect x="237.92" y="20.6523" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="33.3047" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="54.3918" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M247.939 63.9856C248.246 64.0405 248.507 64.2025 248.721 64.4716C248.936 64.7407 249.043 65.0455 249.043 65.3859C249.043 65.677 248.966 65.9406 248.812 66.1767C248.664 66.4073 248.447 66.5913 248.161 66.7286C247.876 66.8604 247.543 66.9263 247.165 66.9263H244.875V61.2015H247.057C247.447 61.2015 247.782 61.2674 248.062 61.3992C248.342 61.5309 248.554 61.7094 248.697 61.9346C248.839 62.1542 248.911 62.4013 248.911 62.6759C248.911 63.0054 248.823 63.28 248.647 63.4996C248.472 63.7193 248.235 63.8813 247.939 63.9856ZM245.814 63.6067H246.975C247.283 63.6067 247.521 63.5381 247.692 63.4008C247.867 63.258 247.955 63.0548 247.955 62.7912C247.955 62.5331 247.867 62.3327 247.692 62.1899C247.521 62.0417 247.283 61.9675 246.975 61.9675H245.814V63.6067ZM247.082 66.1602C247.401 66.1602 247.651 66.0833 247.832 65.9296C248.013 65.7758 248.104 65.5617 248.104 65.2871C248.104 65.007 248.007 64.7846 247.815 64.6199C247.623 64.4551 247.368 64.3728 247.049 64.3728H245.814V66.1602H247.082ZM254.173 62.3876V66.9263H253.234V66.3909C253.086 66.5776 252.891 66.7258 252.65 66.8357C252.413 66.94 252.161 66.9922 251.892 66.9922C251.535 66.9922 251.213 66.918 250.928 66.7698C250.648 66.6215 250.425 66.4018 250.261 66.1108C250.101 65.8197 250.022 65.4683 250.022 65.0564V62.3876H250.953V64.9164C250.953 65.3228 251.054 65.6358 251.257 65.8554C251.461 66.0696 251.738 66.1767 252.089 66.1767C252.441 66.1767 252.718 66.0696 252.921 65.8554C253.13 65.6358 253.234 65.3228 253.234 64.9164V62.3876H254.173ZM259.498 62.3876L256.714 69.0597H255.742L256.664 66.8521L254.877 62.3876H255.923L257.2 65.8472L258.526 62.3876H259.498Z"
          fill="white"
        />
        <rect
          x="371.157"
          y="20.6523"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#1890FF"
          fillOpacity="0.16"
        />
        <path
          d="M399.009 40.773C399.009 37.0327 395.984 34.0076 392.244 34.0076C389.132 34.0076 386.512 36.1145 385.73 38.985C383.371 39.5262 381.612 41.6332 381.612 44.1558C381.612 47.0939 383.99 49.4715 386.928 49.4715C388.629 49.4715 396.835 49.4715 398.526 49.4715C400.933 49.4618 402.875 47.5095 402.875 45.1029C402.875 42.851 401.184 41.0243 399.009 40.773ZM393.21 42.706V44.639C393.21 45.1706 392.775 45.6055 392.244 45.6055C391.712 45.6055 391.277 45.1706 391.277 44.639V42.706H389.547C389.112 42.706 388.9 42.1841 389.209 41.8845L391.905 39.188C392.099 38.9947 392.398 38.9947 392.592 39.188L395.288 41.8845C395.588 42.1841 395.375 42.706 394.95 42.706H393.21Z"
          fill="#1890FF"
        />
      </g>
      <g filter="url(#filter2_d_4011_20345)">
        <rect x="16" y="94.6663" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect
          x="28.6523"
          y="107.319"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect
          x="28.6523"
          y="119.971"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect x="28.6523" y="141.058" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M38.6708 150.652C38.9783 150.707 39.2391 150.869 39.4533 151.138C39.6675 151.407 39.7745 151.712 39.7745 152.052C39.7745 152.343 39.6977 152.607 39.5439 152.843C39.3956 153.074 39.1787 153.258 38.8932 153.395C38.6076 153.527 38.2754 153.593 37.8965 153.593H35.6066V147.868H37.7894C38.1793 147.868 38.5143 147.934 38.7943 148.065C39.0744 148.197 39.2858 148.376 39.4286 148.601C39.5714 148.82 39.6427 149.068 39.6427 149.342C39.6427 149.672 39.5549 149.946 39.3792 150.166C39.2034 150.386 38.9673 150.548 38.6708 150.652ZM36.5456 150.273H37.707C38.0145 150.273 38.2534 150.204 38.4237 150.067C38.5994 149.924 38.6872 149.721 38.6872 149.457C38.6872 149.199 38.5994 148.999 38.4237 148.856C38.2534 148.708 38.0145 148.634 37.707 148.634H36.5456V150.273ZM37.8141 152.826C38.1326 152.826 38.3825 152.75 38.5637 152.596C38.7449 152.442 38.8355 152.228 38.8355 151.953C38.8355 151.673 38.7394 151.451 38.5472 151.286C38.355 151.121 38.0997 151.039 37.7812 151.039H36.5456V152.826H37.8141ZM44.9053 149.054V153.593H43.9663V153.057C43.818 153.244 43.6231 153.392 43.3814 153.502C43.1453 153.606 42.8927 153.658 42.6236 153.658C42.2667 153.658 41.9454 153.584 41.6599 153.436C41.3798 153.288 41.1574 153.068 40.9927 152.777C40.8334 152.486 40.7538 152.135 40.7538 151.723V149.054H41.6846V151.583C41.6846 151.989 41.7862 152.302 41.9894 152.522C42.1925 152.736 42.4699 152.843 42.8213 152.843C43.1728 152.843 43.4501 152.736 43.6533 152.522C43.8619 152.302 43.9663 151.989 43.9663 151.583V149.054H44.9053ZM50.2299 149.054L47.4458 155.726H46.4738L47.3963 153.518L45.6089 149.054H46.655L47.9317 152.513L49.2579 149.054H50.2299Z"
          fill="white"
        />
        <rect
          x="161.889"
          y="107.319"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#754BF2"
          fillOpacity="0.16"
        />
        <path
          d="M192.64 125.062V122.607C192.64 121.544 191.771 120.674 190.707 120.674H175.244C174.18 120.674 173.32 121.534 173.32 122.607V125.062C173.32 125.729 173.639 126.386 174.229 126.695C174.838 127.033 175.244 127.671 175.244 128.406C175.244 129.14 174.828 129.788 174.219 130.107C173.639 130.426 173.311 131.083 173.311 131.75V134.205C173.311 135.268 174.18 136.138 175.244 136.138H190.707C191.771 136.138 192.64 135.268 192.64 134.205V131.75C192.64 131.083 192.312 130.426 191.732 130.107C191.123 129.778 190.707 129.14 190.707 128.406C190.707 127.671 191.123 127.033 191.732 126.705C192.312 126.386 192.64 125.729 192.64 125.062ZM183.942 133.721H182.009V131.789H183.942V133.721ZM183.942 129.372H182.009V127.439H183.942V129.372ZM183.942 125.023H182.009V123.09H183.942V125.023Z"
          fill="#754BF2"
        />
      </g>
      <g filter="url(#filter3_d_4011_20345)">
        <rect x="225.268" y="94.6663" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect x="237.92" y="107.319" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="119.971" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="141.058" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M247.939 150.652C248.246 150.707 248.507 150.869 248.721 151.138C248.936 151.407 249.043 151.712 249.043 152.052C249.043 152.343 248.966 152.607 248.812 152.843C248.664 153.074 248.447 153.258 248.161 153.395C247.876 153.527 247.543 153.593 247.165 153.593H244.875V147.868H247.057C247.447 147.868 247.782 147.934 248.062 148.065C248.342 148.197 248.554 148.376 248.697 148.601C248.839 148.82 248.911 149.068 248.911 149.342C248.911 149.672 248.823 149.946 248.647 150.166C248.472 150.386 248.235 150.548 247.939 150.652ZM245.814 150.273H246.975C247.283 150.273 247.521 150.204 247.692 150.067C247.867 149.924 247.955 149.721 247.955 149.457C247.955 149.199 247.867 148.999 247.692 148.856C247.521 148.708 247.283 148.634 246.975 148.634H245.814V150.273ZM247.082 152.826C247.401 152.826 247.651 152.75 247.832 152.596C248.013 152.442 248.104 152.228 248.104 151.953C248.104 151.673 248.007 151.451 247.815 151.286C247.623 151.121 247.368 151.039 247.049 151.039H245.814V152.826H247.082ZM254.173 149.054V153.593H253.234V153.057C253.086 153.244 252.891 153.392 252.65 153.502C252.413 153.606 252.161 153.658 251.892 153.658C251.535 153.658 251.213 153.584 250.928 153.436C250.648 153.288 250.425 153.068 250.261 152.777C250.101 152.486 250.022 152.135 250.022 151.723V149.054H250.953V151.583C250.953 151.989 251.054 152.302 251.257 152.522C251.461 152.736 251.738 152.843 252.089 152.843C252.441 152.843 252.718 152.736 252.921 152.522C253.13 152.302 253.234 151.989 253.234 151.583V149.054H254.173ZM259.498 149.054L256.714 155.726H255.742L256.664 153.518L254.877 149.054H255.923L257.2 152.513L258.526 149.054H259.498Z"
          fill="white"
        />
        <rect
          x="371.157"
          y="107.319"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#E13672"
          fillOpacity="0.16"
        />
        <path
          d="M384.512 136.138C383.981 136.138 383.526 135.949 383.147 135.57C382.769 135.192 382.579 134.736 382.579 134.205C382.579 133.673 382.769 133.218 383.147 132.839C383.526 132.461 383.981 132.272 384.512 132.272H399.976C400.508 132.272 400.963 132.461 401.342 132.839C401.72 133.218 401.909 133.673 401.909 134.205C401.909 134.736 401.72 135.192 401.342 135.57C400.963 135.949 400.508 136.138 399.976 136.138H384.512ZM385.479 135.171H387.412V133.238H385.479V135.171ZM384.512 124.54C383.981 124.54 383.526 124.35 383.147 123.972C382.769 123.593 382.579 123.138 382.579 122.607C382.579 122.075 382.769 121.62 383.147 121.242C383.526 120.863 383.981 120.674 384.512 120.674H399.976C400.508 120.674 400.963 120.863 401.342 121.242C401.72 121.62 401.909 122.075 401.909 122.607C401.909 123.138 401.72 123.593 401.342 123.972C400.963 124.35 400.508 124.54 399.976 124.54H384.512ZM385.479 123.573H387.412V121.64H385.479V123.573ZM384.512 130.339C383.981 130.339 383.526 130.149 383.147 129.77C382.769 129.392 382.579 128.937 382.579 128.406C382.579 127.874 382.769 127.419 383.147 127.04C383.526 126.662 383.981 126.473 384.512 126.473H399.976C400.508 126.473 400.963 126.662 401.342 127.04C401.72 127.419 401.909 127.874 401.909 128.406C401.909 128.937 401.72 129.392 401.342 129.77C400.963 130.149 400.508 130.339 399.976 130.339H384.512ZM385.479 129.372H387.412V127.439H385.479V129.372Z"
          fill="#E13672"
        />
      </g>
      <g filter="url(#filter4_d_4011_20345)">
        <rect x="16" y="181.332" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect
          x="28.6523"
          y="193.985"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect
          x="28.6523"
          y="206.637"
          width="111.467"
          height="8.43486"
          rx="2.10871"
          fill="#EFF5F6"
        />
        <rect x="28.6523" y="227.724" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M38.6708 237.318C38.9783 237.373 39.2391 237.535 39.4533 237.804C39.6675 238.073 39.7745 238.378 39.7745 238.718C39.7745 239.009 39.6977 239.273 39.5439 239.509C39.3956 239.74 39.1787 239.924 38.8932 240.061C38.6076 240.193 38.2754 240.259 37.8965 240.259H35.6066V234.534H37.7894C38.1793 234.534 38.5143 234.6 38.7943 234.731C39.0744 234.863 39.2858 235.042 39.4286 235.267C39.5714 235.487 39.6427 235.734 39.6427 236.008C39.6427 236.338 39.5549 236.612 39.3792 236.832C39.2034 237.052 38.9673 237.214 38.6708 237.318ZM36.5456 236.939H37.707C38.0145 236.939 38.2534 236.87 38.4237 236.733C38.5994 236.59 38.6872 236.387 38.6872 236.124C38.6872 235.865 38.5994 235.665 38.4237 235.522C38.2534 235.374 38.0145 235.3 37.707 235.3H36.5456V236.939ZM37.8141 239.492C38.1326 239.492 38.3825 239.416 38.5637 239.262C38.7449 239.108 38.8355 238.894 38.8355 238.619C38.8355 238.339 38.7394 238.117 38.5472 237.952C38.355 237.787 38.0997 237.705 37.7812 237.705H36.5456V239.492H37.8141ZM44.9053 235.72V240.259H43.9663V239.723C43.818 239.91 43.6231 240.058 43.3814 240.168C43.1453 240.272 42.8927 240.324 42.6236 240.324C42.2667 240.324 41.9454 240.25 41.6599 240.102C41.3798 239.954 41.1574 239.734 40.9927 239.443C40.8334 239.152 40.7538 238.801 40.7538 238.389V235.72H41.6846V238.249C41.6846 238.655 41.7862 238.968 41.9894 239.188C42.1925 239.402 42.4699 239.509 42.8213 239.509C43.1728 239.509 43.4501 239.402 43.6533 239.188C43.8619 238.968 43.9663 238.655 43.9663 238.249V235.72H44.9053ZM50.2299 235.72L47.4458 242.392H46.4738L47.3963 240.184L45.6089 235.72H46.655L47.9317 239.179L49.2579 235.72H50.2299Z"
          fill="white"
        />
        <rect
          x="161.889"
          y="193.985"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#FFC107"
          fillOpacity="0.16"
        />
        <path
          d="M177.177 222.804C176.371 222.804 175.687 222.522 175.123 221.958C174.559 221.394 174.277 220.71 174.277 219.904C173.746 219.904 173.291 219.715 172.913 219.337C172.534 218.958 172.344 218.503 172.344 217.971V209.273C172.344 208.741 172.534 208.286 172.913 207.908C173.291 207.529 173.746 207.34 174.277 207.34H185.875C186.407 207.34 186.862 207.529 187.241 207.908C187.619 208.286 187.808 208.741 187.808 209.273V211.206H190.224C190.385 211.206 190.53 211.238 190.659 211.302C190.788 211.367 190.901 211.464 190.997 211.592L193.414 214.806C193.478 214.886 193.526 214.975 193.559 215.072C193.591 215.168 193.607 215.273 193.607 215.386V218.938C193.607 219.212 193.514 219.441 193.329 219.626C193.144 219.811 192.914 219.904 192.64 219.904H191.674C191.674 220.71 191.392 221.394 190.828 221.958C190.264 222.522 189.58 222.804 188.774 222.804C187.969 222.804 187.284 222.522 186.721 221.958C186.157 221.394 185.875 220.71 185.875 219.904H180.076C180.076 220.71 179.794 221.394 179.23 221.958C178.667 222.522 177.982 222.804 177.177 222.804ZM177.177 220.871C177.451 220.871 177.68 220.778 177.866 220.592C178.051 220.407 178.143 220.178 178.143 219.904C178.143 219.63 178.051 219.401 177.866 219.216C177.68 219.03 177.451 218.938 177.177 218.938C176.903 218.938 176.673 219.03 176.488 219.216C176.303 219.401 176.21 219.63 176.21 219.904C176.21 220.178 176.303 220.407 176.488 220.592C176.673 220.778 176.903 220.871 177.177 220.871ZM188.774 220.871C189.048 220.871 189.278 220.778 189.463 220.592C189.648 220.407 189.741 220.178 189.741 219.904C189.741 219.63 189.648 219.401 189.463 219.216C189.278 219.03 189.048 218.938 188.774 218.938C188.501 218.938 188.271 219.03 188.086 219.216C187.901 219.401 187.808 219.63 187.808 219.904C187.808 220.178 187.901 220.407 188.086 220.592C188.271 220.778 188.501 220.871 188.774 220.871ZM187.808 216.038H191.916L189.741 213.139H187.808V216.038Z"
          fill="#DBA005"
        />
      </g>
      <g filter="url(#filter5_d_4011_20345)">
        <rect x="225.268" y="181.332" width="200.715" height="78.113" rx="8.43486" fill="white" />
        <rect x="237.92" y="193.985" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="206.637" width="111.467" height="8.43486" rx="2.10871" fill="#EFF5F6" />
        <rect x="237.92" y="227.724" width="28.6725" height="19.069" rx="5.069" fill={color} />
        <path
          d="M247.939 237.318C248.246 237.373 248.507 237.535 248.721 237.804C248.936 238.073 249.043 238.378 249.043 238.718C249.043 239.009 248.966 239.273 248.812 239.509C248.664 239.74 248.447 239.924 248.161 240.061C247.876 240.193 247.543 240.259 247.165 240.259H244.875V234.534H247.057C247.447 234.534 247.782 234.6 248.062 234.731C248.342 234.863 248.554 235.042 248.697 235.267C248.839 235.487 248.911 235.734 248.911 236.008C248.911 236.338 248.823 236.612 248.647 236.832C248.472 237.052 248.235 237.214 247.939 237.318ZM245.814 236.939H246.975C247.283 236.939 247.521 236.87 247.692 236.733C247.867 236.59 247.955 236.387 247.955 236.124C247.955 235.865 247.867 235.665 247.692 235.522C247.521 235.374 247.283 235.3 246.975 235.3H245.814V236.939ZM247.082 239.492C247.401 239.492 247.651 239.416 247.832 239.262C248.013 239.108 248.104 238.894 248.104 238.619C248.104 238.339 248.007 238.117 247.815 237.952C247.623 237.787 247.368 237.705 247.049 237.705H245.814V239.492H247.082ZM254.173 235.72V240.259H253.234V239.723C253.086 239.91 252.891 240.058 252.65 240.168C252.413 240.272 252.161 240.324 251.892 240.324C251.535 240.324 251.213 240.25 250.928 240.102C250.648 239.954 250.425 239.734 250.261 239.443C250.101 239.152 250.022 238.801 250.022 238.389V235.72H250.953V238.249C250.953 238.655 251.054 238.968 251.257 239.188C251.461 239.402 251.738 239.509 252.089 239.509C252.441 239.509 252.718 239.402 252.921 239.188C253.13 238.968 253.234 238.655 253.234 238.249V235.72H254.173ZM259.498 235.72L256.714 242.392H255.742L256.664 240.184L254.877 235.72H255.923L257.2 239.179L258.526 235.72H259.498Z"
          fill="white"
        />
        <rect
          x="371.157"
          y="193.985"
          width="42.1743"
          height="42.1743"
          rx="11.5979"
          fill="#51B4FF"
          fillOpacity="0.16"
        />
        <path
          d="M396.223 211.471V209.387C396.223 207.192 394.438 205.407 392.243 205.407C390.049 205.407 388.264 207.192 388.264 209.387V211.471H391.106V209.387C391.106 208.759 391.615 208.25 392.243 208.25C392.871 208.25 393.381 208.759 393.381 209.387V211.471H396.223Z"
          fill="#51B4FF"
        />
        <path
          d="M392.461 217.753C391.954 217.753 391.542 218.166 391.542 218.673C391.542 219.18 391.954 219.592 392.461 219.592C392.968 219.592 393.381 219.18 393.381 218.673C393.381 218.166 392.968 217.753 392.461 217.753Z"
          fill="#51B4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.406 212.608H397.082C398.176 212.608 399.066 213.498 399.066 214.592V222.787C399.066 223.862 398.192 224.737 397.117 224.737H387.372C386.297 224.737 385.422 223.862 385.422 222.787V214.592C385.422 213.498 386.312 212.608 387.406 212.608ZM395.087 221.894C396.027 221.894 396.792 221.129 396.792 220.189V218.718C396.792 216.295 394.841 214.319 392.443 214.314H392.434C391.27 214.314 390.176 214.767 389.352 215.59C388.526 216.415 388.073 217.513 388.075 218.681C388.077 219.852 388.542 220.953 389.385 221.779C390.21 222.589 391.29 223.031 392.434 223.031C392.459 223.031 392.485 223.031 392.511 223.031L392.491 221.894C391.627 221.908 390.806 221.58 390.182 220.968C389.558 220.356 389.214 219.543 389.212 218.679C389.21 217.816 389.546 217.004 390.156 216.394C390.765 215.786 391.573 215.451 392.434 215.451H392.441C394.213 215.455 395.655 216.921 395.655 218.718V220.189C395.655 220.502 395.4 220.757 395.087 220.757C394.773 220.757 394.518 220.502 394.518 220.189L394.483 218.294C394.304 217.34 393.466 216.616 392.462 216.616C391.328 216.616 390.405 217.539 390.405 218.673C390.405 219.807 391.328 220.729 392.462 220.729C392.804 220.729 393.126 220.645 393.41 220.497C393.555 221.291 394.252 221.894 395.087 221.894Z"
          fill="#51B4FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4011_20345"
          x="0"
          y="0"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4011_20345"
          x="209.268"
          y="0"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4011_20345"
          x="0"
          y="86.6663"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_4011_20345"
          x="209.268"
          y="86.6663"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_4011_20345"
          x="0"
          y="173.332"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_4011_20345"
          x="209.268"
          y="173.332"
          width="232.715"
          height="110.113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20345" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20345"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
