import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageTitle, Tabs, SectionCard, Dropdown } from '@zaveit/uikit';
import { useDispatch, useSelector } from 'react-redux';

import Timeline from 'components/ConsumptionPage/Timeline/Timeline';
import { getData, saveData } from 'helpers/localStorageHelper';
import { AccountState, AuthState, RootState } from 'redux/types';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { getStartDateByPeriod } from 'components/helpers/consumption';
import { getAccounts } from 'api-client/account';
import { getSubscriptions } from 'api-client/order';
import { getConsumptionProperties, getProperty } from 'api-client/consumption';

import useStyles from './ConsumptionPage.styles';
import { TabsEnum, TimelinePeriodsEnum, tabs, timelinePeriods } from './constants';
import PropertyTable from './PropertyTable/PropertyTable';
import SubscriptionTable from './SubscriptionTable/SubscriptionTable';
import CustomerTable from './CustomerTable/CustomerTable';
import { propertyTableMocks } from './mock';
import Tiles from './Tiles/Tiles';
import ExportData from './ExportData/ExportData';

const ConsumptionPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const { selectedAccountView } = useSelector((state: RootState): AccountState => state.account);

  const [consumptions, setConsumptions] = useState([]);
  const [tab, setTab] = useState<number>(TabsEnum.SUBSCRIPTION);
  const [tablesPeriod, setTablesPeriod] = useState<string>(timelinePeriods[0]);
  const [customer, setCustomer] = useState({});
  const [customersLoading, setCustomersLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [property, setProperty] = useState(null);

  const tenant = useMemo(
    () => selectedAccountView?.tenant || userInfo?.tenant,
    [selectedAccountView?.tenant, userInfo?.tenant],
  );

  const handleGetCustomer = useCallback(
    async ({ newOffset = 0, newLimit, newSortModel, newTenant }) => {
      try {
        setCustomersLoading(true);
        const { data } = await getAccounts({
          tenant: newTenant,
          isExtended: true,
          offset: newOffset,
          limit: newLimit,
          pagination: true,
          sortModel: newSortModel,
        });
        setCustomer(data);
      } catch (error) {
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
      setCustomersLoading(false);
    },
    [dispatch],
  );

  const handleGetSubscription = useCallback(
    async ({ newOffset = 0, newLimit, newSortModel, newTenant }) => {
      try {
        setSubscriptionsLoading(true);
        const { data } = await getSubscriptions({
          offset: newOffset,
          limit: newLimit,
          sortModel: newSortModel,
          tenant: newTenant || selectedAccountView?.tenant,
        });

        setSubscription({
          data: data?.data,
          pagination: data?.pagination,
        });
      } catch (error) {
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
      setSubscriptionsLoading(false);
    },
    [dispatch, selectedAccountView],
  );

  const handleGetProperty = useCallback(
    async ({ period }) => {
      try {
        const periods = getStartDateByPeriod(period);
        setPropertiesLoading(true);
        const { data } = await getProperty({ ...periods, tenant: selectedAccountView?.tenant });
        setProperty({ data, pagination: { total: data?.length } });
      } catch (error) {
        setProperty({ data: propertyTableMocks, pagination: { total: propertyTableMocks.length } });
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
      setPropertiesLoading(false);
    },
    [dispatch, selectedAccountView],
  );

  const handleGetConsumptions = useCallback(async () => {
    try {
      const data = await getConsumptionProperties(selectedAccountView?.tenant);
      setConsumptions(data);
    } catch (err) {
      console.error(err);
    }
  }, [selectedAccountView]);

  useEffect(() => {
    handleGetConsumptions();
  }, []);

  useEffect(() => {
    const savedTab = getData('tab');
    const tabValue = tabs.indexOf(savedTab) > -1 ? tabs.indexOf(savedTab) : TabsEnum.SUBSCRIPTION;
    const defaultTablesPeriod = getData('consumptionTables_period') || timelinePeriods[0];

    setTab(tabValue);
    setTablesPeriod(
      tabValue === TabsEnum.PROPERTY ? defaultTablesPeriod : TimelinePeriodsEnum.ALL_TIME,
    );
  }, []);

  const handleUpdateData = useCallback(
    (period) => {
      handleGetProperty({
        period,
      });
    },
    [handleGetProperty],
  );

  const handleChangePeriod = useCallback(
    (e) => {
      const { value } = e.target || {};
      saveData('consumptionTables_period', value);
      setTablesPeriod(value);
      handleUpdateData(value);
    },
    [handleUpdateData],
  );

  const handleChangeTab = useCallback(
    (event, mode) => {
      setTab(mode);
      saveData('tab', tabs[mode]);

      if (mode === TabsEnum.SUBSCRIPTION) {
        setTablesPeriod(timelinePeriods.at(-1));
        handleUpdateData(timelinePeriods.at(-1));
      } else {
        const period = getData('consumptionTables_period') || timelinePeriods[0];
        handleChangePeriod({ target: { value: period } });
      }
    },
    [handleChangePeriod, handleUpdateData],
  );

  const Subscription = useMemo(
    () =>
      userInfo?.child_tenants?.length && !selectedAccountView ? (
        <CustomerTable
          customer={customer}
          handleGetData={handleGetCustomer}
          customersLoading={customersLoading}
          tenant={tenant}
        />
      ) : (
        <SubscriptionTable
          subscription={subscription}
          consumptions={consumptions}
          handleGetData={handleGetSubscription}
          loading={subscriptionsLoading}
          tenant={tenant}
        />
      ),
    [
      tenant,
      consumptions,
      customer,
      customersLoading,
      handleGetCustomer,
      handleGetSubscription,
      selectedAccountView,
      subscription,
      subscriptionsLoading,
      userInfo?.child_tenants?.length,
    ],
  );

  return (
    <>
      <div className={classes.titleContainer}>
        <PageTitle>Consumption</PageTitle>
        {/* Christain Nov. 26: This function was rolled back due to need of improvement and therefore commented out */}
        {/* <ExportData tablesPeriod={tablesPeriod} tenant={selectedAccountView?.tenant} /> */}
      </div>
      <Tiles />
      <Timeline />
      <SectionCard padding="0">
        <div className={classes.tablesHeader}>
          <Tabs handleChangeTab={handleChangeTab} tab={tab} tabsConfig={tabs} />
          <Dropdown
            value={tablesPeriod || ''}
            options={timelinePeriods}
            onChange={handleChangePeriod}
            disabled={tab !== TabsEnum.PROPERTY}
          />
        </div>
        {tab === TabsEnum.SUBSCRIPTION && Subscription}
        {tab === TabsEnum.PROPERTY && (
          <PropertyTable
            property={property}
            tenant={tenant}
            period={tablesPeriod}
            handleGetData={handleGetProperty}
            consumptions={consumptions}
            propertiesLoading={propertiesLoading}
          />
        )}
      </SectionCard>
    </>
  );
};

export default ConsumptionPage;
