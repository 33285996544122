import axios from 'axios';
import Config from 'config/index';
import { Product } from '@zaveit/types';

const instance = axios.create({ baseURL: Config.baseAccountUrl, withCredentials: true });

export const getAllProducts = async (id: string) => {
  try {
    const { data } = await instance.get<Product[]>(`/v2/product/webshop?filter[tenant]=${id}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};
