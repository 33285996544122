import axios from 'axios';

import { PreviewCardData } from 'components/constants';
import Config from 'config/index';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { Risc, RiscHistory } from 'types/risc';

const instance = axios.create({ baseURL: Config.baseRiscUrl, withCredentials: true });

export const getRisc = async (tenant: string, exclude?: boolean) => {
  try {
    const isExclude = exclude ? '!' : '';
    const { data } = await instance.get<Risc>(
      `/v1/risc?filter[metadata.tenant]=${isExclude}${tenant}`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getRiscHistory = async ({
  tenant,
  date,
  now = false,
  granularity = 'day',
  aggregation = 'max',
}: {
  tenant: number;
  date: string;
  now?: boolean;
  granularity?: string;
  aggregation?: string;
}): Promise<RiscHistory[]> => {
  try {
    const { data } = await instance.get('/v1/risc/history', {
      params: {
        'filter[timestamp]': `>${date}`,
        'filter[metadata.tenant]': tenant,
        now,
        granularity,
        aggregation,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getFindings = async (
  tenant: string,
  field?: string,
  isAsc?: boolean,
  limit?: number,
) => {
  try {
    const defaultSortParams = PreviewCardData.findings.sortingTypes[0];
    const sortParams = field
      ? `&sort=${isAsc ? '' : '-'}${field}`
      : `&sort=${defaultSortParams.isAsc ? '' : '-'}${defaultSortParams.field}`;
    const { data } = await instance.get(
      `/v1/finding?filter[metadata.tenant]=${tenant}&filter[status]=open${sortParams}&populate=metadata.related&limit=${
        limit || ''
      }`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getFindingsTable = async (tenant, filter) => {
  try {
    const { data } = await instance.get(
      `/v1/finding?filter[metadata.tenant]=${tenant}&${filter}&populate=metadata.related`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getFinding = async (id) => {
  try {
    const { data } = await instance.get(`/v1/finding/${id}?populate=metadata.related`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getFindingByRisc = async ({
  tenant,
  status,
  risc,
}: {
  tenant: number;
  status: string;
  risc: number;
}) => {
  try {
    const { data } = await instance.get('/v1/finding?', {
      params: {
        'filter[metadata.tenant]': tenant,
        'filter[status]': status,
        'filter[risc_score]': `>${risc}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const resolveFinding = async (id, dispatch) => {
  try {
    const { data } = await instance.put(`/v1/finding/${id}/resolve`);
    return data;
  } catch (error) {
    console.error(error);
    if (dispatch) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not resolve!',
          open: true,
        }),
      );
    }
  }
};

export const resolveFindings = async (ids) => {
  try {
    const { data } = await instance.put('/v1/finding/bulk-resolve', { ids });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const acceptFinding = async (id, dispatch) => {
  try {
    const { data } = await instance.put(`/v1/finding/${id}/accept`);
    return data;
  } catch (error) {
    console.error(error);
    if (dispatch) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not accept!',
          open: true,
        }),
      );
    }
  }
};

export const acceptFindings = async (ids) => {
  try {
    const { data } = await instance.put('/v1/finding/bulk-accept', { ids });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const reportFinding = async (id, dispatch) => {
  try {
    const { data } = await instance.put(`/v1/finding/${id}/false-positive`);
    return data;
  } catch (error) {
    console.error(error);
    if (dispatch) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not report!',
          open: true,
        }),
      );
    }
  }
};

export const getFindingsStaticstics = async (tenant: string) => {
  try {
    const { data } = await instance.get(`/v1/finding/statistic?filter[metadata.tenant]=${tenant}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};
