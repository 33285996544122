import { useEffect, useState, useMemo, useCallback, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Header, RightSideBar } from '@zaveit/header';
import { ThemeProvider } from '@zaveit/uikit';
import { FeatureContextProvider, FeatureRoute } from '@zaveit/feature';
import format from '@zaveit/format';
import { getData, removeData, saveData } from 'helpers/localStorageHelper';
import { handleGetHomeView } from 'helpers/handleGetHomeView';
import { RootState, AuthState, AccountState } from 'redux/types';
import FindingsTable from 'components/FindingsTable';
import StatusBar from 'components/shared/StatusBar/StatusBar';
import GettingStarted from 'components/GettingStarted';
import ConsumptionPage from 'components/ConsumptionPage/ConsumptionPage';
import { getUserInfoRequest, getCurrentTenantAccountRequest } from 'redux/auth/auth.actions';
import {
  getAccountsRequest,
  getSelectedViewAccountsRequest,
  setSelectedAccountView,
} from 'redux/account/account.actions';
import { logOut } from 'api-client/auth';
import { getGettingStartedInfo, updateGettingStartedStatus } from 'api-client/user';
import Config from 'config/index';
import { handleGetRightSidebarData } from 'helpers/rightSidebar';
import { Features } from 'constants/features';
import Home from 'components/Home';
import { useSiteSettings } from 'providers/site-settings';
import useStyles from './App.styles';

enum SideBarModes {
  FULL_VIEW = 'full_view',
  ICONS_VIEW = 'icons_view',
}

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isSidebarOpen = getData('sidebarOpen') != null ? getData('sidebarOpen') : true;
  const isOpen = window.location.pathname === '/dashboard/getting-started' ? false : isSidebarOpen;
  const [isSidebarOpened, setIsSidebarOpened] = useState(isOpen);
  const [isRightSidebarOpened, setIsRightSidebarOpened] = useState<boolean>(false);
  const [sideBarViewAccounts, setSideBarViewAccounts] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [sideBarMode, setSideBarMode] = useState(SideBarModes.FULL_VIEW);
  const [selectedHelpBlock, setSelectedHelpBlock] = useState('');
  const [gettingStartedData, setGettingStarted] = useState(null);
  const [showGettingStarted, setShowGettingStarted] = useState(false);
  const sitename = process.env.REACT_APP_IS_RED_ENV ? 'app.zit.red' : window.location.host;
  const [homeView, setHomeView] = useState();

  const { userInfo, companyName, loading } = useSelector(
    (state: RootState): AuthState => state.auth,
  );
  const siteSettings = useSiteSettings();
  const { accountsList, selectedAccountView } = useSelector(
    (state: RootState): AccountState => state.account,
  );

  const tab = Boolean(userInfo?.child_tenants?.length) ? 2 : 1;

  const [activeTab, setActiveTab] = useState(selectedHelpBlock ? tab : 0);

  const isRightSidebarEnabled = location.pathname === '/consumption';

  const menuAccounts = accountsList.map((account) => ({
    name: account?.name,
    tooltipTitle: `${account.name} / ${account?.number}`,
    onClick: () => {
      dispatch(setSelectedAccountView(account));
    },
    type: 'list',
    id: account?.id || account?._id,
  }));

  const validLocales = ['NO', 'US', 'GB'];
  const country = accountsList.length > 0 ? accountsList[0]?.country : null;
  format.locale(validLocales.includes(country) ? country : 'NO');

  const handleOpenRightSidebar = useCallback(
    (value?: boolean | SyntheticEvent) => {
      const updatedValue = typeof value === 'boolean' ? value : null;

      setIsRightSidebarOpened((prevState) => {
        saveData('isRightSidebarOpened', updatedValue ?? !prevState);
        return updatedValue ?? !prevState;
      });

      if (isRightSidebarOpened) {
        setSelectedHelpBlock('');
      }
    },
    [isRightSidebarOpened],
  );

  const rightSidebarData = handleGetRightSidebarData({
    activeTab,
    setSearch,
    search,
    sideBarViewAccounts,
    selectedAccountView,
    dispatch,
    history,
    selectedHelpBlock,
    handleOpenRightSidebar,
    userInfo,
  });

  const handleGettingStartedRedirect = async () => {
    try {
      const { data, status } = await getGettingStartedInfo();
      setGettingStarted(data);
      if (status !== 204 && data && !data?.status?.completed && !data?.status?.dismissed) {
        history.push('/getting-started');
        setShowGettingStarted(true);
        setIsSidebarOpened(false);
        removeData(['sidebarOpen']);
      }

      if (
        (status === 204 || !data || data?.status?.completed || data?.status?.dismissed) &&
        (location.pathname === '/getting-started' || location.pathname === '/')
      ) {
        history.push('/home');
        setIsSidebarOpened(true);
        saveData('sidebarOpen', true);
        setShowGettingStarted(false);
      }
    } catch (err) {
      console.error(err);
      history.push('/');
      setIsSidebarOpened(true);
      saveData('sidebarOpen', true);
      setShowGettingStarted(false);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (userInfo) {
      handleGettingStartedRedirect();
    } else {
      window.location.href = `https://${sitename}/login`;
    }
    // eslint-disable-next-line
  }, [userInfo, loading, sitename]);

  useEffect(() => {
    dispatch(getUserInfoRequest());
  }, []);

  const favicon = useMemo(() => document.getElementById('favicon'), []);
  const appTitle = useMemo(() => document.getElementById('app-title'), []);

  useEffect(() => {
    if (siteSettings?.favicon) {
      favicon.setAttribute('href', `${Config.baseFileUrl}${siteSettings?.favicon}`);
    }

    if (siteSettings?.title) {
      appTitle.innerText = `${siteSettings?.title}`;
    }
    // eslint-disable-next-line
  }, [siteSettings?.favicon, siteSettings?.title]);

  useEffect(() => {
    if (userInfo?.tenant) {
      dispatch(getCurrentTenantAccountRequest(userInfo?.tenant));
    }
  }, [dispatch, userInfo?.tenant]);

  useEffect(() => {
    if (!menuAccounts?.length) {
      dispatch(getAccountsRequest(userInfo?.tenant));
    }
  }, [dispatch, menuAccounts?.length]);

  useEffect(() => {
    if (userInfo && selectedAccountView) {
      dispatch(getSelectedViewAccountsRequest(selectedAccountView?.tenant));
    }
    // eslint-disable-next-line
  }, [selectedAccountView]);

  useEffect(() => {
    if (userInfo?.role) {
      const type = handleGetHomeView({
        role: userInfo.role,
      });

      setHomeView(type);
    }
  }, [userInfo?.products, userInfo?.role]);

  useEffect(() => {
    if (search) {
      let filteredAccounts = [...sideBarViewAccounts];
      filteredAccounts = menuAccounts.filter((item) =>
        item?.name?.toLowerCase().includes(search.trim().toLowerCase()),
      );
      setSideBarViewAccounts(filteredAccounts);
    } else {
      setSideBarViewAccounts(menuAccounts);
    }
  }, [search, accountsList]);

  useEffect(() => {
    if (isSidebarOpened) {
      const view = isRightSidebarOpened ? SideBarModes.ICONS_VIEW : SideBarModes.FULL_VIEW;
      setSideBarMode(view);
    }
  }, [isRightSidebarOpened, isSidebarOpened]);

  const handleLogout = async () => {
    try {
      await logOut();
      dispatch(getUserInfoRequest());
      removeData(['tab', 'sidebarOpen', 'session']);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGettingStartedClick = async () => {
    try {
      await updateGettingStartedStatus({ reset: 'completed' });
      setShowGettingStarted(true);
      history.push('/getting-started');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ThemeProvider colors={siteSettings?.colors}>
      <FeatureContextProvider session={!!userInfo} userFeatures={userInfo?.features}>
        <StatusBar />
        <Header
          userInfo={userInfo}
          session={!!userInfo}
          handleLogout={handleLogout}
          companyName={companyName}
          colors={siteSettings?.colors}
          logoSrc={siteSettings?.logo ? `${Config.baseFileUrl}${siteSettings?.logo}` : ''}
          setIsSidebarOpened={setIsSidebarOpened}
          isSidebarOpened={isSidebarOpened}
          sideBarMode={sideBarMode}
          setSideBarMode={setSideBarMode}
          isRightSidebarOpened={isRightSidebarOpened}
          setIsRightSidebarOpened={setIsRightSidebarOpened}
          handleOpenRightSidebar={isRightSidebarEnabled && handleOpenRightSidebar}
          urlConfig={Config}
          showGettingStarted={showGettingStarted}
          handleGettingStartedClick={gettingStartedData ? handleGettingStartedClick : null}
        />
        {userInfo && (
          <RightSideBar
            isRightSidebarOpened={isRightSidebarOpened}
            data={rightSidebarData}
            onChangeTab={setActiveTab}
          />
        )}

        {userInfo && (
          <div
            className={classnames({
              [classes.container]: true,
              [classes.containerMenuOpen]: isSidebarOpened,
              [classes.containerRightSidebarOpened]: isRightSidebarOpened,
              [classes.containerSidebarOpenedIconView]:
                !isRightSidebarOpened && sideBarMode === SideBarModes.ICONS_VIEW && isSidebarOpened,
            })}
          >
            <Switch>
              <Route exact path="/home" render={() => <Home view={homeView} />} />
              <Route exact path="/findings" component={FindingsTable} />
              <FeatureRoute
                exact
                path="/consumption"
                feature={Features.consumption}
                component={ConsumptionPage}
              />
              <Route
                exact
                path="/getting-started"
                render={() => (
                  <GettingStarted
                    data={gettingStartedData}
                    setIsSidebarOpened={setIsSidebarOpened}
                    setShowGettingStarted={setShowGettingStarted}
                  />
                )}
              />
              <Redirect to="/home" />
            </Switch>
          </div>
        )}
      </FeatureContextProvider>
    </ThemeProvider>
  );
}

export default App;
