import format from '@zaveit/format';

export const formatTitle = (title = '') => {
  const formattedTitle = title.replace(/_/g, ' ');
  return formattedTitle.charAt(0).toUpperCase() + formattedTitle.slice(1);
};

export const resetTitleFormatting = (title) => title.replace(' ', '_').toLowerCase();

export const formatValue = (type, value) => {
  if (!value && value !== 0) {
    return undefined;
  }

  if (type === 'numeric') {
    return format.number(value).thousands;
  }
  if (type === 'byte') {
    return format.byte(value).short;
  }
  return value;
};
