import { makeStyles } from '@material-ui/core';
import { Shadows } from 'styles/constants';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  cardStyle: {
    borderRadius: '16px',
    boxShadow: Shadows.cardShadow,
    padding: '24px',
  },
  bannerContainer: {
    display: 'flex',
    padding: '40px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '40px',
    flex: '1',
  },
  bannerTextContainer: {
    maxWidth: '600px',
  },
  bannerText: {
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#303642',
  },
  productsMarketingCard: {
    display: 'flex',
    height: '350px',
    minWidth: '420px',
    padding: '24px 0px 24px 24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flex: '1',
    overflow: 'hidden',
  },
  productTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '24px',
  },
  productImage: {
    alignSelf: 'flex-end',
  },
  productImageHorizontal: {
    alignSelf: 'flex-end',
    paddingBottom: '-24px',
  },
  serviceImage: {
    alignSelf: 'center',
  },
  servicesMarketingCard: {
    display: 'flex',
    height: '350px',
    minWidth: '420px',
    padding: '24px 0px 24px 24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flex: '1',
    overflow: 'hidden',
  },
  horizontalMarketingContainer: {
    height: '350px',
    minWidth: '420px',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 0px 0px 24px',
    justifyContent: 'space-between',
    flex: '1',
    overflow: 'hidden',
  },
  horizontalServicesCard: {
    height: '350px',
    minWidth: '420px',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    justifyContent: 'space-between',
    flex: '1',
    overflow: 'hidden',
  },
  horizontalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '24px',
  },
  grid: {
    gap: '24px',
  },
  flex: {
    display: 'flex',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
}));

export default useStyles;
