import { createActions } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------

export const SET_IS_LOGIN_FORM_OPEN = 'SET_IS_LOGIN_FORM_OPEN';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const GET_CURRENT_TENANT_ACCOUNT_REQUEST = 'GET_CURRENT_TENANT_ACCOUNT_REQUEST';
export const GET_CURRENT_TENANT_ACCOUNT_SUCCESS = 'GET_CURRENT_TENANT_ACCOUNT_SUCCESS';
export const GET_CURRENT_TENANT_ACCOUNT_FAILURE = 'GET_CURRENT_TENANT_ACCOUNT_FAILURE';

// ------------------------------------
// Synchronous Action Creators
// ------------------------------------

export const {
  setIsLoginFormOpen,
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  getCurrentTenantAccountRequest,
  getCurrentTenantAccountSuccess,
  getCurrentTenantAccountFailure,
} = createActions(
  SET_IS_LOGIN_FORM_OPEN,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  GET_CURRENT_TENANT_ACCOUNT_REQUEST,
  GET_CURRENT_TENANT_ACCOUNT_SUCCESS,
  GET_CURRENT_TENANT_ACCOUNT_FAILURE,
);
