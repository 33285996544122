import { call, put, takeEvery } from 'redux-saga/effects';
import { getFindings } from 'api-client/target';
import { GET_FINDINGS_REQUEST, getFindingsFailure, getFindingsSuccess } from './targets.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadFindings() {
  try {
    const { data } = yield call(getFindings);
    yield put(getFindingsSuccess(data));
  } catch (error) {
    yield put(getFindingsFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* targetsApi() {
  yield takeEvery(GET_FINDINGS_REQUEST, loadFindings);
}

export default targetsApi;
