import axios from 'axios';
import Config from 'config/index';
import { Offering } from '@zaveit/types';

const instance = axios.create({ baseURL: Config.baseAccountUrl, withCredentials: true });

export const getAllServices = async (id: string) => {
  try {
    const { data } = await instance.get<Offering[]>(`/v1/product/${id}/offerings`);
    return data;
  } catch (error) {
    console.error(error);
  }
};
