import { Dispatch, SetStateAction, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FindingDetailsModal, DashboardCard, Button } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';
import { getFindings } from 'api-client/risc';
import {
  handleReportFinding,
  handleAcceptFinding,
  handleResolveFinding,
} from 'components/FindingsTable/helpers';
import PreviewItem from 'components/shared/PreviewCard/PreviewItem';
import { updateProviderFindingsData, updateConsumerFindingsData } from 'components/helpers';
import { PreviewCardData, PreviewCardPlaceholder } from 'components/constants';
import { TFinding, TCustomer, TTarget } from 'components/types';
import useRedirectToSupport from 'components/hooks/useRedirectToSupport';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { handleServiceClick } from 'components/helpers/findings';
import useStyles from './Findings.styles';
import { AuthState, RootState } from '../../redux/types';

type TFindings = {
  tenant: string;
  values: TFinding[];
  customers: TCustomer[];
  targets?: TTarget[];
  isMsp?: boolean;
  handleChange: Dispatch<SetStateAction<TFinding[]>>;
  isLoading: boolean;
  refetchRisc?: () => void;
};

const Findings = ({
  tenant,
  values,
  customers,
  targets,
  isMsp,
  handleChange,
  isLoading,
  refetchRisc,
}: TFindings) => {
  const dispatch = useDispatch();
  const { redirectToSupport } = useRedirectToSupport();
  const classes = useStyles({});
  const [selectedFinding, setSelectedFinding] = useState<TFinding>(null);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const history = useHistory();
  const [parentFinding, setParentFinding] = useState<TFinding>(null);
  const [breadcrumbsConfig, setBreadcrumbsConfig] = useState([]);
  const [sorting, setSorting] = useState({
    field: 'risc_score',
    isAsc: false,
  });

  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);

  const defaultBreadcrumbsConfig: any = [
    {
      name: 'Finding',
      tenant: '',
      disabled: true,
    },
    {
      name: parentFinding?.title,
      tenant: parentFinding?.metadata?.tenant,
      item: parentFinding,
    },
  ];

  const handleChangeSortingType = useCallback(
    async ({ field, isAsc }) => {
      try {
        const { data: findingsData } = await getFindings(tenant, field, isAsc, 5);
        setSorting({ field, isAsc });
        if (isMsp) {
          handleChange(updateProviderFindingsData(findingsData, customers));
        } else {
          handleChange(updateConsumerFindingsData(findingsData, targets));
        }
      } catch (error) {
        return error;
      }
    },
    [customers, handleChange, isMsp, targets, tenant],
  );

  const handleItemClick = (value) => {
    setSelectedFinding(value);
    setParentFinding(value);
    setDetailsOpen(true);
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setSelectedFinding(null);
    setParentFinding(null);
  };

  useEffect(() => {
    if (parentFinding && detailsOpen) {
      setBreadcrumbsConfig(defaultBreadcrumbsConfig);
    } else {
      setBreadcrumbsConfig([]);
    }
  }, [parentFinding, detailsOpen]);

  const handleClickRelatedFinding = (value: TFinding) => {
    setSelectedFinding(value);
    const newConfig = [
      ...breadcrumbsConfig,
      {
        name: value?.title,
        tenant: value?.metadata?.tenant,
        item: value,
      },
    ];
    setBreadcrumbsConfig(newConfig);
  };

  const handleBreadcrumbsClick = (value) => {
    setSelectedFinding(value);
    if (value?._id === parentFinding?._id) {
      setBreadcrumbsConfig(defaultBreadcrumbsConfig);
    } else {
      const index = breadcrumbsConfig.findIndex((el) => el._id === value?._id);
      const newConfig = breadcrumbsConfig.slice(0, index);
      setBreadcrumbsConfig(newConfig);
    }
  };

  const handleUpdateSelectedFinding = (value) => {
    const newRelatedFindings = selectedFinding?.metadata?.related?.filter(
      (item) => item?._id !== value?._id,
    );
    setSelectedFinding((prevState) => ({
      ...prevState,
      metadata: { ...prevState.metadata, related: [...newRelatedFindings] },
    }));
  };

  const refetchFindings = async () => {
    try {
      const { field, isAsc } = sorting;
      const { data } = await getFindings(tenant, field, isAsc, 5);
      if (isMsp) {
        handleChange(updateProviderFindingsData(data, customers));
      } else {
        handleChange(updateConsumerFindingsData(data, targets));
      }
      refetchRisc?.();
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not get findings!',
          open: true,
        }),
      );
    }
  };

  return (
    <>
      <DashboardCard
        cardTitle={PreviewCardData.findings.title}
        sortingOptions={PreviewCardData.findings.sortingTypes}
        handleChangeSortingType={handleChangeSortingType}
        showSorting={Boolean(values?.length)}
        isLoading={isLoading}
        emptyViewConfig={!values?.length ? PreviewCardPlaceholder.findings : null}
        footerContent={
          values?.length ? (
            <div className={classes.buttonCardContainer}>
              <Button onClick={() => history.push('/findings')} styleClass="textGrey">
                <span> View all</span>
                <Icon iconName="chevron_right" className={classes.btnIcon} />
              </Button>
            </div>
          ) : null
        }
      >
        <div className={classes.rowsContainer}>
          {values?.map((item) => (
            <PreviewItem
              itemData={item}
              onItemClick={handleItemClick}
              type={PreviewCardData.findings.name}
            />
          ))}
        </div>
      </DashboardCard>
      <FindingDetailsModal
        open={detailsOpen}
        handleClose={handleModalClose}
        finding={selectedFinding}
        breadcrumbsConfig={breadcrumbsConfig}
        handleClick={(value) => handleBreadcrumbsClick(value)}
        handleServiceClick={(service) => handleServiceClick({ service, selectedFinding, userInfo })}
        handleResolve={(id) => {
          handleResolveFinding({ id, dispatch, handleSuccess: refetchFindings });
          setDetailsOpen(false);
        }}
        handleResolveRelatedFinding={(id) =>
          handleResolveFinding({
            id,
            handleSuccess: handleUpdateSelectedFinding,
            isRelated: true,
          })
        }
        handleAccept={(id) => {
          handleAcceptFinding({ id, dispatch, handleSuccess: refetchFindings });
          setDetailsOpen(false);
        }}
        handleAcceptRelatedFinding={(id) =>
          handleAcceptFinding({ id, handleSuccess: handleUpdateSelectedFinding, isRelated: true })
        }
        handleReport={(id) => {
          handleReportFinding({ id, dispatch, handleSuccess: redirectToSupport });
          setDetailsOpen(false);
        }}
        handleReportRelatedFinding={(id) =>
          handleReportFinding({ id, handleSuccess: redirectToSupport })
        }
        handleClickRelatedFinding={(value: TFinding) => handleClickRelatedFinding(value)}
      />
    </>
  );
};

export default Findings;
