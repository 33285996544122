import { getSiteSettings } from 'api-client/user';
import { ReactNode, useEffect, useState } from 'react';
import { SiteSettings } from '@zaveit/types';
import SiteSettingsContext from './context';

function SiteSettingsProvider({ children }: { children: ReactNode }) {
  const sitename = process.env.REACT_APP_IS_RED_ENV ? 'app.zit.red' : window.location.host;
  const [siteSettings, setSiteSettings] = useState<SiteSettings>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSiteSettings({ sitename });
        setSiteSettings(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <SiteSettingsContext.Provider value={siteSettings}>{children}</SiteSettingsContext.Provider>
  );
}

export default SiteSettingsProvider;
