export default function PortalBannerSvg({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 282 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 117.06C0 141.473 8.77582 163.72 23.217 180.534C39.7843 199.828 63.8086 211.959 90.5878 212C102.291 212.017 113.482 209.711 123.761 205.501C129.076 203.323 135.025 203.573 140.172 206.153C146.211 209.176 152.972 210.87 160.112 210.87C163.061 210.87 165.949 210.579 168.743 210.026C176.718 208.454 183.975 204.732 189.935 199.461C193.68 196.148 198.477 194.482 203.38 194.488H203.463C219.663 194.488 234.711 189.345 247.205 180.534C258.374 172.666 267.495 161.868 273.572 149.19C278.87 138.142 281.852 125.662 281.852 112.453C281.852 67.1493 246.754 30.4181 203.463 30.4181C199.05 30.4181 194.726 30.8082 190.508 31.5361C179.339 12.614 159.338 0 136.521 0C126.888 0 117.756 2.24819 109.581 6.27828C101.807 10.0985 94.8949 15.5268 89.2406 22.1422C70.0411 22.4626 52.2883 29.0263 37.7582 39.9805C14.8862 57.2136 0 85.3196 0 117.06Z"
          fill="url(#paint0_linear_4011_20298)"
        />
      </g>
      <path
        opacity="0.24"
        d="M81.6978 47C81.6978 47 27.8776 70.8263 26.0253 104.003C24.1729 137.178 124.645 131.196 146.615 145.502C168.586 159.808 263.809 181.549 261.974 156.629C260.139 131.709 237.516 123.111 237.516 123.111"
        stroke={color}
        strokeWidth="0.976958"
        strokeDasharray="1.47 1.47"
      />
      <path
        opacity="0.12"
        d="M222.478 154.179L189.663 66.8372C189.126 65.4162 188.168 64.1916 186.916 63.3271C185.664 62.4632 184.177 62 182.655 62H44.4712C42.4881 62.0046 40.5878 62.7936 39.1872 64.1942C37.7865 65.5942 37 67.4915 37 69.4696C37.0023 70.2291 37.1178 70.9833 37.3427 71.7085L69.0954 170.844C69.6131 172.447 70.6614 173.828 72.0686 174.76C73.4757 175.691 75.1584 176.118 76.8406 175.972L216.121 164.178C218.1 164.016 219.934 163.078 221.219 161.569C222.504 160.059 223.136 158.102 222.975 156.128C222.895 155.459 222.728 154.804 222.478 154.179Z"
        fill={color}
      />
      <path
        d="M40.3618 62.6942L72.3366 162.798C72.8658 164.414 73.9242 165.805 75.3407 166.744C76.7571 167.683 78.4482 168.116 80.1407 167.973L122.073 164.42L220.083 156.036C221.068 155.96 222.028 155.689 222.909 155.24C223.789 154.791 224.572 154.172 225.213 153.418C225.853 152.665 226.338 151.791 226.641 150.849C226.943 149.907 227.056 148.914 226.974 147.929C226.904 147.22 226.742 146.524 226.491 145.859L218.653 125.158L204.474 87.2072L193.483 57.8813C192.943 56.4471 191.98 55.2112 190.721 54.3393C189.462 53.4668 187.968 53 186.437 53H47.5113C46.5249 53 45.5482 53.1943 44.6368 53.5722C43.7256 53.9501 42.8975 54.5044 42.2 55.2026C41.5025 55.9008 40.9492 56.7302 40.5718 57.6426C40.1943 58.5549 40 59.5329 40 60.5208C40.0103 61.2594 40.1322 61.9921 40.3618 62.6942Z"
        fill="white"
      />
      <path
        d="M122 164.463L80.3549 167.973C78.6534 168.116 76.9532 167.683 75.5293 166.744C74.1053 165.805 73.0413 164.414 72.5093 162.798L40.3637 62.6942C40.1329 61.9921 40.0103 61.2594 40 60.5208C40 59.5329 40.1953 58.5549 40.5748 57.6426C40.9543 56.7302 41.5105 55.9008 42.2118 55.2026C42.913 54.5044 43.7454 53.9501 44.6616 53.5722C45.5778 53.1943 46.5598 53 47.5514 53H85.7977L122 164.463Z"
        fill={color}
      />
      <path
        opacity="0.18"
        d="M159.501 108.485C161.24 108.377 162.409 106.661 161.866 105.01L156.219 87.8278C155.845 86.6931 154.757 85.946 153.56 86.0031L112.537 87.9678C110.762 88.0527 109.567 89.8137 110.148 91.4871L116.305 109.215C116.696 110.341 117.793 111.069 118.987 110.995L159.501 108.485Z"
        fill={color}
      />
      <path
        opacity="0.18"
        d="M170.549 139.485C172.255 139.377 173.401 137.661 172.869 136.01L167.33 118.828C166.963 117.693 165.897 116.946 164.723 117.003L124.488 118.968C122.748 119.053 121.575 120.814 122.146 122.487L128.184 140.215C128.567 141.341 129.643 142.069 130.814 141.995L170.549 139.485Z"
        fill={color}
      />
      <path
        opacity="0.18"
        d="M176.137 70.1764L186.758 105.061C186.915 105.622 187.255 106.117 187.725 106.468C188.195 106.819 188.769 107.005 189.358 107L215.705 106.246C216.619 106.221 217.235 105.312 216.914 104.466L203.593 69.2471C203.409 68.7584 202.95 68.4241 202.424 68.3931L178.51 67.0021C178.117 66.9856 177.726 67.0648 177.371 67.233C177.015 67.4011 176.707 67.6524 176.473 67.9657C176.239 68.279 176.085 68.6443 176.027 69.0295C175.968 69.4153 176.006 69.8091 176.137 70.1764Z"
        fill={color}
      />
      <path
        d="M239.816 97.1517L229.065 65.6212C228.9 65.1149 228.595 64.67 228.188 64.3425C227.781 64.0144 227.29 63.8179 226.776 63.7767L181.466 61.0011C181.08 60.989 180.697 61.0734 180.349 61.2476C180.002 61.4218 179.701 61.6804 179.471 62.0011C179.241 62.3224 179.089 62.6958 179.029 63.0908C178.969 63.4857 179.002 63.8895 179.125 64.2682L189.52 99.9975C189.676 100.573 190.008 101.08 190.467 101.441C190.926 101.803 191.487 101.999 192.064 102L237.747 100.577C238.129 100.554 238.499 100.437 238.827 100.235C239.156 100.033 239.433 99.7531 239.636 99.4182C239.839 99.0827 239.961 98.7019 239.992 98.3076C240.023 97.9133 239.963 97.517 239.816 97.1517Z"
        fill="white"
      />
      <path
        d="M224.618 92.3052C224.908 93.1258 225.053 93.5358 225.362 93.7679C225.673 94 226.075 94 226.881 94H227.071C228.677 94 229.48 94 229.841 93.4254C230.202 92.8501 229.912 92.0334 229.332 90.4007L228.371 87.6902C228.081 86.8722 227.936 86.4635 227.626 86.2321C227.316 86 226.914 86 226.11 86H225.925C224.322 86 223.521 86 223.16 86.574C222.798 87.148 223.086 87.964 223.662 89.5953L224.618 92.3052Z"
        fill={color}
      />
      <path
        d="M210.392 92.2904C210.694 93.1178 210.845 93.5316 211.167 93.7658C211.491 94 211.91 94 212.75 94H212.949C214.621 94 215.458 94 215.834 93.4195C216.211 92.8389 215.909 92.0148 215.305 90.3666L213.596 85.7049C213.294 84.8795 213.143 84.4671 212.82 84.2336C212.497 84 212.078 84 211.24 84H211.049C209.378 84 208.543 84 208.166 84.5792C207.79 85.1584 208.09 85.9819 208.691 87.6287L210.392 92.2904Z"
        fill={color}
      />
      <path
        d="M203.057 92.2647C203.377 93.1042 203.537 93.5243 203.879 93.7618C204.221 94 204.665 94 205.553 94H205.768C207.54 94 208.425 94 208.824 93.4096C209.223 92.8198 208.903 91.9817 208.264 90.3055L201.943 73.7353C201.623 72.8951 201.463 72.4757 201.121 72.2375C200.779 72 200.335 72 199.447 72H199.232C197.46 72 196.575 72 196.176 72.5897C195.777 73.1801 196.097 74.0176 196.736 75.6938L203.057 92.2647Z"
        fill={color}
      />
      <path
        d="M217.046 92.2339C217.367 93.0883 217.528 93.5158 217.87 93.7576C218.213 94 218.657 94 219.547 94H219.762C221.537 94 222.424 94 222.824 93.3991C223.224 92.7989 222.903 91.9459 222.263 90.2399L217.954 78.7661C217.633 77.911 217.472 77.4842 217.13 77.2417C216.787 77 216.343 77 215.453 77H215.238C213.463 77 212.576 77 212.176 77.6009C211.776 78.2011 212.097 79.0541 212.737 80.7594L217.046 92.2339Z"
        fill={color}
      />
      <path
        d="M195.186 92.2723C195.499 93.1088 195.656 93.5267 195.99 93.763C196.325 94 196.76 94 197.628 94H197.839C199.572 94 200.438 94 200.828 93.4133C201.218 92.8266 200.906 91.9928 200.281 90.3258L191.814 67.727C191.501 66.8912 191.344 66.4733 191.01 66.237C190.675 66 190.24 66 189.372 66H189.161C187.428 66 186.562 66 186.172 66.5867C185.782 67.1734 186.094 68.0072 186.719 69.6742L195.186 92.2723Z"
        fill={color}
      />
      <path
        d="M60.3455 84.2361C59.6623 81.578 60.0006 78.7447 61.2875 76.346C62.5745 73.9474 64.7072 72.1758 67.2263 71.4121C69.7454 70.6484 72.4494 70.954 74.7558 72.2634C77.0626 73.5728 78.7866 75.781 79.558 78.4122C80.3287 81.0434 80.0849 83.8877 78.8786 86.3319C77.6717 88.7761 75.5994 90.625 73.1072 91.4804C70.6149 92.3358 67.9025 92.1289 65.5539 90.905C63.2054 89.6812 61.4086 87.5378 60.5506 84.9363L60.4481 84.5948L60.3455 84.2361Z"
        fill={color}
      />
      <path
        d="M82.2098 148.137C81.6943 145.646 82.1367 143.052 83.4494 140.874C84.7621 138.696 86.8477 137.096 89.2882 136.393C91.7292 135.69 94.3448 135.936 96.6114 137.083C98.878 138.23 100.629 140.193 101.513 142.578V142.904C101.513 143.007 101.513 143.127 101.632 143.23C102.311 145.673 102.044 148.284 100.885 150.538C99.7266 152.793 97.7609 154.527 95.3817 155.394C93.0024 156.26 90.3849 156.196 88.0505 155.214C85.7167 154.231 83.8385 152.402 82.7917 150.093C82.689 149.767 82.6035 149.441 82.5008 149.115C82.3981 148.789 82.3296 148.464 82.2098 148.137Z"
        fill={color}
      />
      <path
        d="M72 113.146C72 110.454 73.1062 107.874 75.0752 105.972C77.0443 104.069 79.7154 103 82.4999 103C85.2851 103 87.9555 104.069 89.9246 105.972C91.8938 107.874 93 110.454 93 113.146V113.854C93 116.545 91.8938 119.125 89.9246 121.028C87.9555 122.931 85.2851 124 82.4999 124C79.7154 124 77.0443 122.931 75.0752 121.028C73.1062 119.125 72 116.545 72 113.854V113.146Z"
        fill={color}
      />
      <path
        d="M70.8128 81.0029L68.3791 73.368C69.0662 73.1606 69.7772 73.0394 70.4954 73.0056C72.2914 72.94 74.0598 73.4521 75.5291 74.4644C76.998 75.4766 78.086 76.9321 78.6256 78.6074C78.8371 79.2792 78.9618 79.9734 78.9957 80.6757C79.0181 81.3759 78.953 82.0761 78.8018 82.7611C78.4907 84.1298 77.8081 85.3918 76.8265 86.4153C75.8592 87.4554 74.6249 88.2245 73.2513 88.6425C71.8778 89.0612 70.415 89.1129 69.0139 88.7936C68.3235 88.6359 67.6545 88.3987 67.021 88.0867C66.3842 87.7581 65.7918 87.3527 65.2574 86.8803C64.216 85.9383 63.4388 84.7518 63 83.4335L70.8128 81.0029Z"
        fill={color}
      />
      <path
        d="M81.9567 112.996V105C83.3531 105.011 84.7225 105.385 85.9305 106.086C86.562 106.426 87.1412 106.856 87.651 107.361C88.6461 108.35 89.3622 109.586 89.7272 110.942C90.0915 112.299 90.0908 113.728 89.7258 115.085C89.3609 116.44 88.6441 117.676 87.6477 118.665C86.6519 119.654 85.4128 120.362 84.0553 120.716C82.7052 121.095 81.2769 121.095 79.9268 120.716C79.2536 120.542 78.6063 120.281 78 119.94L81.9567 112.996Z"
        fill={color}
      />
      <path
        d="M92.3695 146.199L90 138.399C91.2765 137.962 92.6365 137.882 93.9497 138.169C95.5956 138.545 97.0795 139.494 98.172 140.869C99.2638 142.245 99.9033 143.969 99.9893 145.775C100.025 146.487 99.9703 147.201 99.8248 147.898C99.6901 148.598 99.4687 149.276 99.167 149.914C98.7229 150.871 98.1074 151.724 97.356 152.425C96.6051 153.126 95.7328 153.661 94.7891 154L92.4195 146.182L92.3695 146.199Z"
        fill={color}
      />
      <path d="M109 32L91 39.9147L94.038 50L109 32Z" fill={color} />
      <path d="M109 32H81L89.5213 37L109 32Z" fill={color} />
      <path d="M87 45L91.6004 40.3158L109 32L90.1409 37.2105L87 45Z" fill={color} />
      <path d="M87 45L91.6004 40.3158L109 32L87 45Z" fill={color} />
      <path d="M87 45L91.6004 40.3158L109 32L90.1409 37.2105L87 45Z" fill={color} />
      <defs>
        <linearGradient
          id="paint0_linear_4011_20298"
          x1="193.075"
          y1="249.708"
          x2="297.264"
          y2="15.5621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  );
}
