export default function ServicesIllustration({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 500 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.659668"
        y="33.3333"
        width="133.333"
        height="133.333"
        rx="36.6667"
        fill={color}
        fillOpacity="0.16"
      />
      <path
        d="M64.8512 67.2021L43.4623 76.7049C41.2623 77.6827 39.8262 79.8827 39.8262 82.2966V96.6577C39.8262 113.616 51.5595 129.474 67.3262 133.324C83.0928 129.474 94.8262 113.616 94.8262 96.6577V82.2966C94.8262 79.8827 93.3901 77.6827 91.1901 76.7049L69.8012 67.2021C68.2428 66.4994 66.4095 66.4994 64.8512 67.2021V67.2021ZM67.3262 99.6827H88.7151C87.0956 112.272 78.6928 123.485 67.3262 126.999V99.7132H45.9373V82.2966L67.3262 72.7938V99.6827Z"
        fill={color}
      />
      <rect x="149.993" width="200" height="200" rx="55" fill={color} fillOpacity="0.16" />
      <path
        d="M219.253 110.427C217.878 109.052 217.229 107.409 217.305 105.5C217.382 103.59 218.146 102.1 219.597 101.031C223.798 97.8226 228.478 95.3201 233.636 93.5234C238.79 91.7298 244.232 90.833 249.962 90.833C255.767 90.833 261.249 91.7497 266.407 93.583C271.561 95.4163 276.239 97.9754 280.441 101.26C281.892 102.33 282.638 103.801 282.677 105.674C282.714 107.544 282.045 109.166 280.67 110.541C279.371 111.84 277.787 112.509 275.917 112.549C274.044 112.586 272.305 112.069 270.701 111C267.722 108.937 264.495 107.353 261.021 106.247C257.544 105.138 253.857 104.583 249.962 104.583C246.066 104.583 242.381 105.156 238.907 106.302C235.429 107.448 232.239 109.014 229.337 111C227.656 112.146 225.881 112.662 224.011 112.549C222.138 112.433 220.552 111.725 219.253 110.427ZM199.774 91.0622C198.475 89.7636 197.826 88.1777 197.826 86.3047C197.826 84.4347 198.514 82.8886 199.889 81.6663C206.687 75.9372 214.326 71.4486 222.805 68.2005C231.285 64.9555 240.337 63.333 249.962 63.333C259.587 63.333 268.639 64.9555 277.118 68.2005C285.597 71.4486 293.236 75.9372 300.034 81.6663C301.409 82.8886 302.117 84.4163 302.157 86.2497C302.193 88.083 301.524 89.6872 300.149 91.0622C298.85 92.3608 297.266 93.0483 295.396 93.1247C293.523 93.2011 291.823 92.6281 290.295 91.4059C284.719 86.899 278.513 83.3851 271.677 80.8643C264.839 78.3434 257.6 77.083 249.962 77.083C242.323 77.083 235.086 78.3434 228.25 80.8643C221.412 83.3851 215.205 86.899 209.628 91.4059C208.1 92.6281 206.402 93.2011 204.532 93.1247C202.659 93.0483 201.073 92.3608 199.774 91.0622ZM249.962 136.666C247.364 136.666 245.187 135.788 243.43 134.031C241.673 132.274 240.795 130.097 240.795 127.5C240.795 124.902 241.673 122.725 243.43 120.968C245.187 119.211 247.364 118.333 249.962 118.333C252.559 118.333 254.736 119.211 256.493 120.968C258.25 122.725 259.128 124.902 259.128 127.5C259.128 130.097 258.25 132.274 256.493 134.031C254.736 135.788 252.559 136.666 249.962 136.666Z"
        fill={color}
      />
      <rect
        x="365.993"
        y="33.3333"
        width="133.333"
        height="133.333"
        rx="36.6667"
        fill={color}
        fillOpacity="0.16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.591 129.181C410.507 130.097 411.577 130.556 412.799 130.556H452.521C453.744 130.556 454.813 130.097 455.73 129.181C456.646 128.264 457.105 127.195 457.105 125.972V88.0835C457.105 87.4724 456.977 86.874 456.723 86.2883C456.468 85.7027 456.137 85.2062 455.73 84.7988L441.751 70.8196C441.343 70.4122 440.847 70.0812 440.261 69.8265C439.675 69.5719 439.077 69.4446 438.466 69.4446H412.799C411.577 69.4446 410.507 69.9029 409.591 70.8196C408.674 71.7362 408.216 72.8057 408.216 74.0279V125.972C408.216 127.195 408.674 128.264 409.591 129.181ZM438.733 87.587C438.3 87.1541 438.084 86.6066 438.084 85.9446V74.0279L452.521 88.2363H440.376C439.714 88.2363 439.166 88.0198 438.733 87.587ZM435.711 110.374V116.485C435.711 118.165 434.336 119.54 432.655 119.54C430.975 119.54 429.6 118.165 429.6 116.485V110.374H424.13C422.755 110.374 422.083 108.724 423.061 107.777L431.586 99.2515C432.197 98.6404 433.144 98.6404 433.755 99.2515L442.28 107.777C443.227 108.724 442.555 110.374 441.211 110.374H435.711Z"
        fill={color}
      />
    </svg>
  );
}
