import axios from 'axios';
import dayjs from 'dayjs';
import { Ticket, TicketHistory } from 'types/support';

import Config from '../config/index';

const instance = axios.create({ baseURL: Config.baseSupportUrl, withCredentials: true });

export const getTickets = async ({
  status = undefined,
  tenant = '',
  limit = null,
  field = '',
  isAsc = null,
  filter = '',
  filterValue = '',
}) => {
  try {
    const sortParams = field ? `${isAsc ? '' : '-'}${field}` : '';
    const { data } = await instance.get('/v1/support/ticket', {
      params: {
        'filter[status]': status,
        'filter[tenant]': tenant,
        limit,
        sort: sortParams,
        [filter]: filterValue,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserTickets = async ({
  status = undefined,
  limit = 5,
  field = '',
  isAsc = null,
  filter = '',
  filterValue = '',
}) => {
  try {
    const sortParams = field ? `${isAsc ? '' : '-'}${field}` : '';
    const { data } = await instance.get('/v1/support/ticket/my', {
      params: {
        'filter[status]': status,
        limit,
        sort: sortParams,
        [filter]: filterValue,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getTicketHistory = async ({ days, tenant }: { days: number; tenant: number }) => {
  try {
    const { data } = await instance.get<TicketHistory>('/v1/support/ticket/history', {
      params: {
        days,
        'filter[tenant]': `!${tenant}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTicketsAfterDate = async ({ tenant, date }: { tenant: number; date: string }) => {
  try {
    const { data } = await instance.get<Ticket[]>('/v1/support/ticket', {
      params: {
        'filter[created]': `>${date}`,
        'filter[tenant]': `!${tenant}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerTickets = async ({
  tenant,
  status,
}: {
  tenant: number;
  status: string;
}) => {
  try {
    const { data } = await instance.get<Ticket[]>('/v1/support/ticket', {
      params: {
        'filter[status]': status,
        'filter[tenant]': `!${tenant}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
