export default function ProductsIllustrationLarge({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 689 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4011_20803)">
        <rect x="27.3711" y="14" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="48.8684"
          y="35.4973"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect
          x="48.8684"
          y="56.9946"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="48.8684" y="92.8235" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M65.8903 109.133C66.4128 109.227 66.856 109.502 67.2199 109.959C67.5838 110.416 67.7657 110.934 67.7657 111.513C67.7657 112.007 67.6351 112.455 67.3738 112.856C67.1219 113.248 66.7534 113.561 66.2682 113.794C65.783 114.018 65.2185 114.13 64.5747 114.13H60.6839V104.403H64.3928C65.0552 104.403 65.6244 104.515 66.1002 104.739C66.5761 104.963 66.9353 105.266 67.1779 105.649C67.4205 106.022 67.5418 106.442 67.5418 106.908C67.5418 107.468 67.3925 107.935 67.0939 108.308C66.7953 108.681 66.3941 108.956 65.8903 109.133ZM62.2794 108.49H64.2528C64.7753 108.49 65.1812 108.373 65.4704 108.14C65.769 107.897 65.9183 107.552 65.9183 107.104C65.9183 106.666 65.769 106.325 65.4704 106.082C65.1812 105.831 64.7753 105.705 64.2528 105.705H62.2794V108.49ZM64.4348 112.828C64.9759 112.828 65.4004 112.698 65.7084 112.436C66.0163 112.175 66.1702 111.811 66.1702 111.345C66.1702 110.869 66.0069 110.491 65.6804 110.211C65.3538 109.931 64.9199 109.791 64.3788 109.791H62.2794V112.828H64.4348ZM76.4833 106.418V114.13H74.8878V113.22C74.6359 113.537 74.3047 113.789 73.8941 113.976C73.4929 114.153 73.0637 114.242 72.6065 114.242C72.0001 114.242 71.4542 114.116 70.969 113.864C70.4932 113.612 70.1153 113.239 69.8354 112.744C69.5648 112.25 69.4295 111.653 69.4295 110.953V106.418H71.011V110.715C71.011 111.405 71.1836 111.937 71.5289 112.31C71.8741 112.674 72.3453 112.856 72.9424 112.856C73.5396 112.856 74.0107 112.674 74.356 112.31C74.7105 111.937 74.8878 111.405 74.8878 110.715V106.418H76.4833ZM85.5302 106.418L80.7997 117.755H79.1482L80.7158 114.004L77.6787 106.418H79.4561L81.6255 112.296L83.8787 106.418H85.5302Z"
          fill="white"
        />
        <rect
          x="275.248"
          y="35.4973"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#5FBB50"
          fillOpacity="0.16"
        />
        <path
          d="M309.747 53.6998L298.252 58.8069C297.069 59.3324 296.297 60.5147 296.297 61.812V69.5301C296.297 78.6441 302.603 87.1669 311.077 89.236C319.55 87.1669 325.856 78.6441 325.856 69.5301V61.812C325.856 60.5147 325.084 59.3324 323.902 58.8069L312.407 53.6998C311.569 53.3221 310.584 53.3221 309.747 53.6998ZM311.077 71.1559H322.572C321.701 77.9215 317.186 83.9482 311.077 85.8367V71.1723H299.582V61.812L311.077 56.7049V71.1559Z"
          fill="#5FBB50"
        />
      </g>
      <g filter="url(#filter1_d_4011_20803)">
        <rect x="382.935" y="14" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="404.433"
          y="35.4973"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect
          x="404.433"
          y="56.9946"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="404.433" y="92.8235" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M421.455 109.133C421.977 109.227 422.42 109.502 422.784 109.959C423.148 110.416 423.33 110.934 423.33 111.513C423.33 112.007 423.199 112.455 422.938 112.856C422.686 113.248 422.318 113.561 421.832 113.794C421.347 114.018 420.783 114.13 420.139 114.13H416.248V104.403H419.957C420.619 104.403 421.189 104.515 421.664 104.739C422.14 104.963 422.5 105.266 422.742 105.649C422.985 106.022 423.106 106.442 423.106 106.908C423.106 107.468 422.957 107.935 422.658 108.308C422.36 108.681 421.958 108.956 421.455 109.133ZM417.844 108.49H419.817C420.34 108.49 420.745 108.373 421.035 108.14C421.333 107.897 421.482 107.552 421.482 107.104C421.482 106.666 421.333 106.325 421.035 106.082C420.745 105.831 420.34 105.705 419.817 105.705H417.844V108.49ZM419.999 112.828C420.54 112.828 420.965 112.698 421.273 112.436C421.58 112.175 421.734 111.811 421.734 111.345C421.734 110.869 421.571 110.491 421.245 110.211C420.918 109.931 420.484 109.791 419.943 109.791H417.844V112.828H419.999ZM432.048 106.418V114.13H430.452V113.22C430.2 113.537 429.869 113.789 429.458 113.976C429.057 114.153 428.628 114.242 428.171 114.242C427.564 114.242 427.018 114.116 426.533 113.864C426.057 113.612 425.68 113.239 425.4 112.744C425.129 112.25 424.994 111.653 424.994 110.953V106.418H426.575V110.715C426.575 111.405 426.748 111.937 427.093 112.31C427.438 112.674 427.909 112.856 428.507 112.856C429.104 112.856 429.575 112.674 429.92 112.31C430.275 111.937 430.452 111.405 430.452 110.715V106.418H432.048ZM441.094 106.418L436.364 117.755H434.712L436.28 114.004L433.243 106.418H435.02L437.19 112.296L439.443 106.418H441.094Z"
          fill="white"
        />
        <rect
          x="630.812"
          y="35.4973"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#1890FF"
          fillOpacity="0.16"
        />
        <path
          d="M678.136 69.6838C678.136 63.3287 672.996 58.1887 666.641 58.1887C661.353 58.1887 656.903 61.7686 655.572 66.6458C651.566 67.5654 648.577 71.1453 648.577 75.4313C648.577 80.4235 652.617 84.4632 657.609 84.4632C660.499 84.4632 674.441 84.4632 677.315 84.4632C681.404 84.4468 684.704 81.1296 684.704 77.0406C684.704 73.2144 681.831 70.1108 678.136 69.6838ZM668.283 72.9681V76.2524C668.283 77.1556 667.544 77.8946 666.641 77.8946C665.737 77.8946 664.998 77.1556 664.998 76.2524V72.9681H662.059C661.32 72.9681 660.959 72.0813 661.484 71.5723L666.066 66.9907C666.394 66.6622 666.903 66.6622 667.232 66.9907L671.813 71.5723C672.322 72.0813 671.961 72.9681 671.239 72.9681H668.283Z"
          fill="#1890FF"
        />
      </g>
      <g filter="url(#filter2_d_4011_20803)">
        <rect x="27.3711" y="161.466" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="48.8684"
          y="182.963"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="48.8684" y="204.46" width="189.392" height="14.3315" rx="3.58288" fill="#EFF5F6" />
        <rect x="48.8684" y="240.289" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M65.8903 256.599C66.4128 256.693 66.856 256.968 67.2199 257.425C67.5838 257.882 67.7657 258.4 67.7657 258.979C67.7657 259.473 67.6351 259.921 67.3738 260.322C67.1219 260.714 66.7534 261.027 66.2682 261.26C65.783 261.484 65.2185 261.596 64.5747 261.596H60.6839V251.869H64.3928C65.0552 251.869 65.6244 251.981 66.1002 252.205C66.5761 252.429 66.9353 252.732 67.1779 253.114C67.4205 253.488 67.5418 253.907 67.5418 254.374C67.5418 254.934 67.3925 255.4 67.0939 255.774C66.7953 256.147 66.3941 256.422 65.8903 256.599ZM62.2794 255.955H64.2528C64.7753 255.955 65.1812 255.839 65.4704 255.606C65.769 255.363 65.9183 255.018 65.9183 254.57C65.9183 254.131 65.769 253.791 65.4704 253.548C65.1812 253.296 64.7753 253.17 64.2528 253.17H62.2794V255.955ZM64.4348 260.294C64.9759 260.294 65.4004 260.163 65.7084 259.902C66.0163 259.641 66.1702 259.277 66.1702 258.811C66.1702 258.335 66.0069 257.957 65.6804 257.677C65.3538 257.397 64.9199 257.257 64.3788 257.257H62.2794V260.294H64.4348ZM76.4833 253.884V261.596H74.8878V260.686C74.6359 261.003 74.3047 261.255 73.8941 261.442C73.4929 261.619 73.0637 261.708 72.6065 261.708C72.0001 261.708 71.4542 261.582 70.969 261.33C70.4932 261.078 70.1153 260.705 69.8354 260.21C69.5648 259.716 69.4295 259.118 69.4295 258.419V253.884H71.011V258.181C71.011 258.871 71.1836 259.403 71.5289 259.776C71.8741 260.14 72.3453 260.322 72.9424 260.322C73.5396 260.322 74.0107 260.14 74.356 259.776C74.7105 259.403 74.8878 258.871 74.8878 258.181V253.884H76.4833ZM85.5302 253.884L80.7997 265.221H79.1482L80.7158 261.47L77.6787 253.884H79.4561L81.6255 259.762L83.8787 253.884H85.5302Z"
          fill="white"
        />
        <rect
          x="275.248"
          y="182.963"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#754BF2"
          fillOpacity="0.16"
        />
        <path
          d="M327.497 213.11V208.939C327.497 207.132 326.019 205.655 324.213 205.655H297.938C296.132 205.655 294.67 207.116 294.67 208.939V213.11C294.67 214.243 295.212 215.36 296.214 215.885C297.249 216.46 297.938 217.544 297.938 218.792C297.938 220.04 297.232 221.14 296.198 221.682C295.212 222.224 294.654 223.341 294.654 224.474V228.645C294.654 230.451 296.132 231.929 297.938 231.929H324.213C326.019 231.929 327.497 230.451 327.497 228.645V224.474C327.497 223.341 326.939 222.224 325.953 221.682C324.919 221.124 324.213 220.04 324.213 218.792C324.213 217.544 324.919 216.46 325.953 215.902C326.939 215.36 327.497 214.243 327.497 213.11ZM312.718 227.824H309.433V224.539H312.718V227.824ZM312.718 220.434H309.433V217.15H312.718V220.434ZM312.718 213.044H309.433V209.76H312.718V213.044Z"
          fill="#754BF2"
        />
      </g>
      <g filter="url(#filter3_d_4011_20803)">
        <rect x="382.935" y="161.466" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="404.433"
          y="182.963"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="404.433" y="204.46" width="189.392" height="14.3315" rx="3.58288" fill="#EFF5F6" />
        <rect x="404.433" y="240.289" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M421.455 256.599C421.977 256.693 422.42 256.968 422.784 257.425C423.148 257.882 423.33 258.4 423.33 258.979C423.33 259.473 423.199 259.921 422.938 260.322C422.686 260.714 422.318 261.027 421.832 261.26C421.347 261.484 420.783 261.596 420.139 261.596H416.248V251.869H419.957C420.619 251.869 421.189 251.981 421.664 252.205C422.14 252.429 422.5 252.732 422.742 253.114C422.985 253.488 423.106 253.907 423.106 254.374C423.106 254.934 422.957 255.4 422.658 255.774C422.36 256.147 421.958 256.422 421.455 256.599ZM417.844 255.955H419.817C420.34 255.955 420.745 255.839 421.035 255.606C421.333 255.363 421.482 255.018 421.482 254.57C421.482 254.131 421.333 253.791 421.035 253.548C420.745 253.296 420.34 253.17 419.817 253.17H417.844V255.955ZM419.999 260.294C420.54 260.294 420.965 260.163 421.273 259.902C421.58 259.641 421.734 259.277 421.734 258.811C421.734 258.335 421.571 257.957 421.245 257.677C420.918 257.397 420.484 257.257 419.943 257.257H417.844V260.294H419.999ZM432.048 253.884V261.596H430.452V260.686C430.2 261.003 429.869 261.255 429.458 261.442C429.057 261.619 428.628 261.708 428.171 261.708C427.564 261.708 427.018 261.582 426.533 261.33C426.057 261.078 425.68 260.705 425.4 260.21C425.129 259.716 424.994 259.118 424.994 258.419V253.884H426.575V258.181C426.575 258.871 426.748 259.403 427.093 259.776C427.438 260.14 427.909 260.322 428.507 260.322C429.104 260.322 429.575 260.14 429.92 259.776C430.275 259.403 430.452 258.871 430.452 258.181V253.884H432.048ZM441.094 253.884L436.364 265.221H434.712L436.28 261.47L433.243 253.884H435.02L437.19 259.762L439.443 253.884H441.094Z"
          fill="white"
        />
        <rect
          x="630.812"
          y="182.963"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#E13672"
          fillOpacity="0.16"
        />
        <path
          d="M653.504 231.929C652.601 231.929 651.828 231.608 651.185 230.965C650.541 230.321 650.219 229.548 650.219 228.645C650.219 227.742 650.541 226.968 651.185 226.324C651.828 225.682 652.601 225.36 653.504 225.36H679.778C680.681 225.36 681.455 225.682 682.099 226.324C682.741 226.968 683.063 227.742 683.063 228.645C683.063 229.548 682.741 230.321 682.099 230.965C681.455 231.608 680.681 231.929 679.778 231.929H653.504ZM655.146 230.287H658.43V227.003H655.146V230.287ZM653.504 212.223C652.601 212.223 651.828 211.901 651.185 211.258C650.541 210.615 650.219 209.842 650.219 208.939C650.219 208.036 650.541 207.263 651.185 206.62C651.828 205.976 652.601 205.655 653.504 205.655H679.778C680.681 205.655 681.455 205.976 682.099 206.62C682.741 207.263 683.063 208.036 683.063 208.939C683.063 209.842 682.741 210.615 682.099 211.258C681.455 211.901 680.681 212.223 679.778 212.223H653.504ZM655.146 210.581H658.43V207.297H655.146V210.581ZM653.504 222.076C652.601 222.076 651.828 221.754 651.185 221.11C650.541 220.468 650.219 219.695 650.219 218.792C650.219 217.889 650.541 217.115 651.185 216.471C651.828 215.829 652.601 215.507 653.504 215.507H679.778C680.681 215.507 681.455 215.829 682.099 216.471C682.741 217.115 683.063 217.889 683.063 218.792C683.063 219.695 682.741 220.468 682.099 221.11C681.455 221.754 680.681 222.076 679.778 222.076H653.504ZM655.146 220.434H658.43V217.15H655.146V220.434Z"
          fill="#E13672"
        />
      </g>
      <g filter="url(#filter4_d_4011_20803)">
        <rect x="27.3711" y="308.932" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="48.8684"
          y="330.429"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect
          x="48.8684"
          y="351.926"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="48.8684" y="387.755" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M65.8903 404.065C66.4128 404.158 66.856 404.434 67.2199 404.891C67.5838 405.348 67.7657 405.866 67.7657 406.444C67.7657 406.939 67.6351 407.387 67.3738 407.788C67.1219 408.18 66.7534 408.492 66.2682 408.726C65.783 408.95 65.2185 409.062 64.5747 409.062H60.6839V399.335H64.3928C65.0552 399.335 65.6244 399.447 66.1002 399.671C66.5761 399.894 66.9353 400.198 67.1779 400.58C67.4205 400.953 67.5418 401.373 67.5418 401.84C67.5418 402.4 67.3925 402.866 67.0939 403.239C66.7953 403.613 66.3941 403.888 65.8903 404.065ZM62.2794 403.421H64.2528C64.7753 403.421 65.1812 403.305 65.4704 403.071C65.769 402.829 65.9183 402.484 65.9183 402.036C65.9183 401.597 65.769 401.257 65.4704 401.014C65.1812 400.762 64.7753 400.636 64.2528 400.636H62.2794V403.421ZM64.4348 407.76C64.9759 407.76 65.4004 407.629 65.7084 407.368C66.0163 407.107 66.1702 406.743 66.1702 406.276C66.1702 405.801 66.0069 405.423 65.6804 405.143C65.3538 404.863 64.9199 404.723 64.3788 404.723H62.2794V407.76H64.4348ZM76.4833 401.35V409.062H74.8878V408.152C74.6359 408.469 74.3047 408.721 73.8941 408.908C73.4929 409.085 73.0637 409.173 72.6065 409.173C72.0001 409.173 71.4542 409.048 70.969 408.796C70.4932 408.544 70.1153 408.17 69.8354 407.676C69.5648 407.181 69.4295 406.584 69.4295 405.885V401.35H71.011V405.647C71.011 406.337 71.1836 406.869 71.5289 407.242C71.8741 407.606 72.3453 407.788 72.9424 407.788C73.5396 407.788 74.0107 407.606 74.356 407.242C74.7105 406.869 74.8878 406.337 74.8878 405.647V401.35H76.4833ZM85.5302 401.35L80.7997 412.686H79.1482L80.7158 408.936L77.6787 401.35H79.4561L81.6255 407.228L83.8787 401.35H85.5302Z"
          fill="white"
        />
        <rect
          x="275.248"
          y="330.429"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#FFC107"
          fillOpacity="0.16"
        />
        <path
          d="M301.223 379.395C299.855 379.395 298.692 378.916 297.734 377.958C296.776 377 296.297 375.837 296.297 374.468C295.394 374.468 294.621 374.147 293.978 373.504C293.335 372.861 293.013 372.087 293.013 371.184V356.405C293.013 355.501 293.335 354.729 293.978 354.086C294.621 353.442 295.394 353.12 296.297 353.12H316.003C316.906 353.12 317.679 353.442 318.323 354.086C318.966 354.729 319.287 355.501 319.287 356.405V359.689H323.392C323.666 359.689 323.912 359.744 324.131 359.853C324.35 359.963 324.542 360.127 324.706 360.346L328.811 365.806C328.921 365.943 329.003 366.093 329.058 366.257C329.112 366.422 329.14 366.6 329.14 366.791V372.826C329.14 373.291 328.982 373.681 328.667 373.995C328.353 374.311 327.963 374.468 327.498 374.468H325.855C325.855 375.837 325.377 377 324.419 377.958C323.461 378.916 322.297 379.395 320.929 379.395C319.561 379.395 318.397 378.916 317.44 377.958C316.482 377 316.003 375.837 316.003 374.468H306.15C306.15 375.837 305.671 377 304.713 377.958C303.755 378.916 302.592 379.395 301.223 379.395ZM301.223 376.11C301.689 376.11 302.079 375.953 302.394 375.637C302.708 375.323 302.866 374.933 302.866 374.468C302.866 374.003 302.708 373.613 302.394 373.299C302.079 372.984 301.689 372.826 301.223 372.826C300.758 372.826 300.368 372.984 300.053 373.299C299.738 373.613 299.581 374.003 299.581 374.468C299.581 374.933 299.738 375.323 300.053 375.637C300.368 375.953 300.758 376.11 301.223 376.11ZM320.929 376.11C321.394 376.11 321.784 375.953 322.098 375.637C322.414 375.323 322.571 374.933 322.571 374.468C322.571 374.003 322.414 373.613 322.098 373.299C321.784 372.984 321.394 372.826 320.929 372.826C320.464 372.826 320.074 372.984 319.76 373.299C319.445 373.613 319.287 374.003 319.287 374.468C319.287 374.933 319.445 375.323 319.76 375.637C320.074 375.953 320.464 376.11 320.929 376.11ZM319.287 367.9H326.266L322.571 362.973H319.287V367.9Z"
          fill="#DBA005"
        />
      </g>
      <g filter="url(#filter5_d_4011_20803)">
        <rect x="382.935" y="308.932" width="341.032" height="132.933" rx="14.3315" fill="white" />
        <rect
          x="404.433"
          y="330.429"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect
          x="404.433"
          y="351.926"
          width="189.392"
          height="14.3315"
          rx="3.58288"
          fill="#EFF5F6"
        />
        <rect x="404.433" y="387.755" width="48.5316" height="32.6127" rx="8.61265" fill={color} />
        <path
          d="M421.455 404.065C421.977 404.158 422.42 404.434 422.784 404.891C423.148 405.348 423.33 405.866 423.33 406.444C423.33 406.939 423.199 407.387 422.938 407.788C422.686 408.18 422.318 408.492 421.832 408.726C421.347 408.95 420.783 409.062 420.139 409.062H416.248V399.335H419.957C420.619 399.335 421.189 399.447 421.664 399.671C422.14 399.894 422.5 400.198 422.742 400.58C422.985 400.953 423.106 401.373 423.106 401.84C423.106 402.4 422.957 402.866 422.658 403.239C422.36 403.613 421.958 403.888 421.455 404.065ZM417.844 403.421H419.817C420.34 403.421 420.745 403.305 421.035 403.071C421.333 402.829 421.482 402.484 421.482 402.036C421.482 401.597 421.333 401.257 421.035 401.014C420.745 400.762 420.34 400.636 419.817 400.636H417.844V403.421ZM419.999 407.76C420.54 407.76 420.965 407.629 421.273 407.368C421.58 407.107 421.734 406.743 421.734 406.276C421.734 405.801 421.571 405.423 421.245 405.143C420.918 404.863 420.484 404.723 419.943 404.723H417.844V407.76H419.999ZM432.048 401.35V409.062H430.452V408.152C430.2 408.469 429.869 408.721 429.458 408.908C429.057 409.085 428.628 409.173 428.171 409.173C427.564 409.173 427.018 409.048 426.533 408.796C426.057 408.544 425.68 408.17 425.4 407.676C425.129 407.181 424.994 406.584 424.994 405.885V401.35H426.575V405.647C426.575 406.337 426.748 406.869 427.093 407.242C427.438 407.606 427.909 407.788 428.507 407.788C429.104 407.788 429.575 407.606 429.92 407.242C430.275 406.869 430.452 406.337 430.452 405.647V401.35H432.048ZM441.094 401.35L436.364 412.686H434.712L436.28 408.936L433.243 401.35H435.02L437.19 407.228L439.443 401.35H441.094Z"
          fill="white"
        />
        <rect
          x="630.812"
          y="330.429"
          width="71.6576"
          height="71.6576"
          rx="19.7058"
          fill="#51B4FF"
          fillOpacity="0.16"
        />
        <path
          d="M673.401 360.14V356.598C673.401 352.87 670.368 349.836 666.64 349.836C662.911 349.836 659.878 352.87 659.878 356.598V360.14H664.708V356.598C664.708 355.531 665.573 354.666 666.64 354.666C667.707 354.666 668.571 355.531 668.571 356.598V360.14H673.401Z"
          fill="#51B4FF"
        />
        <path
          d="M667.01 370.814C666.148 370.814 665.447 371.514 665.447 372.376C665.447 373.237 666.148 373.938 667.009 373.938C667.871 373.938 668.572 373.237 668.572 372.376C668.572 371.514 667.871 370.814 667.01 370.814Z"
          fill="#51B4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M658.42 362.072H674.861C676.72 362.072 678.232 363.584 678.232 365.443V379.367C678.232 381.193 676.746 382.679 674.919 382.679H658.362C656.535 382.679 655.049 381.193 655.049 379.367V365.443C655.049 363.584 656.561 362.072 658.42 362.072ZM671.471 377.85C673.069 377.85 674.369 376.55 674.369 374.952V372.454C674.369 368.336 671.054 364.979 666.979 364.97H666.963C664.985 364.97 663.126 365.74 661.727 367.138C660.324 368.54 659.553 370.406 659.557 372.389C659.56 374.38 660.351 376.25 661.783 377.655C663.185 379.029 665.019 379.781 666.963 379.781C667.006 379.781 667.051 379.781 667.094 379.78L667.061 377.849C665.591 377.874 664.197 377.316 663.136 376.275C662.077 375.236 661.492 373.855 661.489 372.386C661.486 370.92 662.056 369.541 663.093 368.504C664.127 367.471 665.501 366.902 666.963 366.902H666.975C669.986 366.908 672.437 369.399 672.437 372.454V374.952C672.437 375.484 672.003 375.918 671.471 375.918C670.938 375.918 670.505 375.484 670.505 374.952L670.444 371.732C670.141 370.112 668.717 368.882 667.011 368.882C665.084 368.882 663.516 370.449 663.516 372.376C663.516 374.302 665.084 375.87 667.011 375.87C667.591 375.87 668.139 375.727 668.621 375.475C668.868 376.824 670.051 377.85 671.471 377.85Z"
          fill="#51B4FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4011_20803"
          x="0.18577"
          y="0.407338"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4011_20803"
          x="355.75"
          y="0.407338"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4011_20803"
          x="0.18577"
          y="147.873"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_4011_20803"
          x="355.75"
          y="147.873"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_4011_20803"
          x="0.18577"
          y="295.339"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_4011_20803"
          x="355.75"
          y="295.339"
          width="395.402"
          height="187.304"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.5927" />
          <feGaussianBlur stdDeviation="13.5927" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4011_20803" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4011_20803"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
