import { HomeViews } from 'helpers/handleGetHomeView';
import ProviderAdminView from './HomeProviderAdmin';
import HomeCustomerPortal from './HomeCustomerPortal';

export default function Home({ view }) {
  if (view === HomeViews.CONSUMER_ADMIN_VIEW) {
    return <HomeCustomerPortal />;
  }

  if (view === HomeViews.PROVIDER_ADMIN_VIEW) {
    return <ProviderAdminView />;
  }

  return null;
}
