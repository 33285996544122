import { Container, Grid, Typography } from '@material-ui/core';
import PortalBannerSvg from 'static/svg/PortalBannerSvg';
import { Button } from '@zaveit/uikit';
import ProductsIllustration from 'static/svg/ProductsIllustration';
import ProductsIllustrationLarge from 'static/svg/ProductsIllustrationLarge';
import ServicesIllustration from 'static/svg/ServicesIllustration';
import ServicesIllustrationLarge from 'static/svg/ServicesIllustrationLarge';
import { useSelector } from 'react-redux';
import { getAllProducts } from 'api-client/products';
import { useEffect, useState } from 'react';
import { getAllServices } from 'api-client/services';
import Skeleton from '@mui/material/Skeleton';
import { useSiteSettings } from 'providers/site-settings';
import { UserToken } from '@zaveit/types';
import useStyles from './HomeCustomerPortal.styles';

interface CardProps {
  brandColor: string;
}

const ProductsCard = ({ brandColor }: CardProps) => {
  const classes = useStyles();

  return (
    <div className={`${classes.productsMarketingCard} ${classes.cardStyle}`}>
      <div>
        <Typography variant="h4">Explore</Typography>
        <Typography variant="h4">our products</Typography>
      </div>
      <div className={classes.productImage}>
        <ProductsIllustration color={brandColor} height="225" width="350px" />
      </div>
      <div>
        <div>
          <Button
            styleClass="black"
            onClick={() => {
              window.location.href = `https://${window.location.host}/shop/products`;
            }}
          >
            Shop now
          </Button>
        </div>
      </div>
    </div>
  );
};

const ServicesCard = ({ brandColor }: CardProps) => {
  const classes = useStyles();

  return (
    <div className={`${classes.servicesMarketingCard} ${classes.cardStyle}`}>
      <div>
        <Typography variant="h4">Explore</Typography>
        <Typography variant="h4">our Services</Typography>
      </div>
      <div className={classes.serviceImage}>
        <ServicesIllustration color={brandColor} height="225" width="480px" />
      </div>
      <div>
        <div>
          <Button
            styleClass="black"
            onClick={() => {
              window.location.href = `https://${window.location.host}/order/services`;
            }}
          >
            Shop now
          </Button>
        </div>
      </div>
    </div>
  );
};

const ProductsCardHorizontal = ({ brandColor }: CardProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <div className={`${classes.horizontalMarketingContainer} ${classes.cardStyle}`}>
        <div className={classes.productTitleContainer}>
          <div>
            <Typography variant="h4">Explore</Typography>
            <Typography variant="h4">our products</Typography>
          </div>
          <div>
            <Button
              styleClass="black"
              onClick={() => {
                window.location.href = `https://${window.location.host}/shop/products`;
              }}
            >
              Shop now
            </Button>
          </div>
        </div>
        <div className={classes.productImageHorizontal}>
          <ProductsIllustrationLarge color={brandColor} height="350" width="600" />
        </div>
      </div>
    </Grid>
  );
};

const ServicesCardHorizontal = ({ brandColor }: CardProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <div className={`${classes.horizontalServicesCard} ${classes.cardStyle}`}>
        <div className={classes.flex}>
          <div>
            <Typography variant="h4">Explore</Typography>
            <Typography variant="h4">our services</Typography>
          </div>
        </div>
        <div className={classes.alignSelfCenter}>
          <ServicesIllustrationLarge color={brandColor} height="200" width="1000px" />
        </div>

        <Button
          styleClass="black"
          onClick={() => {
            window.location.href = `https://${window.location.host}/order/services`;
          }}
        >
          Shop now
        </Button>
      </div>
    </Grid>
  );
};

const HomeCustomerPortal = () => {
  const classes = useStyles();
  const { userInfo } = useSelector((state: { auth: { userInfo: UserToken } }) => state.auth);
  const [hasActiveProducts, setHasActiveProducts] = useState<boolean>(false);
  const [hasActiveServices, setHasActiveServices] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const siteSettings = useSiteSettings();

  const brandColor = siteSettings ? siteSettings?.colors?.primary_color : '#00C9A7'; // fallback color to zaveit

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [servicesRes, productsRes] = await Promise.all([
          getAllServices(userInfo?.parent?.tenant),
          getAllProducts(userInfo?.parent?.tenant),
        ]);

        setHasActiveServices(!!servicesRes);
        if (siteSettings?.webshopEnabled) setHasActiveProducts(!!productsRes);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userInfo?.parent?.tenant]);

  return (
    <Container className={classes.container}>
      <Grid container>
        <div className={`${classes.bannerContainer} ${classes.cardStyle}`}>
          <div className={classes.bannerTextContainer}>
            <Typography variant="h4">Welcome to {userInfo?.parent?.name}</Typography>
            <p className={classes.bannerText}>
              Track your orders, manage subscriptions, or browse through our products and services -
              all here in one portal.
            </p>
          </div>
          <div>
            <PortalBannerSvg color={brandColor} height={212} width={281} />
          </div>
        </div>
      </Grid>

      {isLoading && (
        <div className={classes.cardStyle}>
          <Skeleton variant="rounded" width="100%" height="350px" />
        </div>
      )}

      {!isLoading && hasActiveServices && hasActiveProducts && (
        <Grid container className={classes.grid}>
          <ProductsCard brandColor={brandColor} />
          <ServicesCard brandColor={brandColor} />
        </Grid>
      )}

      {!isLoading && hasActiveServices && !hasActiveProducts && (
        <Grid container className={classes.grid}>
          <ServicesCardHorizontal brandColor={brandColor} />
        </Grid>
      )}

      {!isLoading && hasActiveProducts && !hasActiveServices && (
        <Grid container className={classes.grid}>
          <ProductsCardHorizontal brandColor={brandColor} />
        </Grid>
      )}
    </Container>
  );
};

export default HomeCustomerPortal;
