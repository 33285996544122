import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    transition: 'padding-left 0.2s ease-in-out',
    width: '80%',
    maxWidth: '1500px',
    margin: '65px auto 0px auto',
    padding: '40px 0 40px 20px',
    height: 'calc(100vh - 65px)',
  },
  containerMenuOpen: {
    paddingLeft: '280px',
    transition: 'padding-left 0.2s',
    maxWidth: 'calc(100% - 380px)',
  },
  mainContainerBlur: {
    background: 'rgba(255, 255, 255, 0.2)',
    filter: 'blur(5px)',
    height: '100vh',
    width: '100%',
  },
  containerRightSidebarOpened: {
    position: 'relative',
    marginRight: '300px',
    paddingLeft: '108px',
    transition: 'all 0.2s',
    boxSizing: 'content-box',
    maxWidth: 'calc(100% - 408px)',
  },
  containerSidebarOpenedIconView: {
    position: 'relative',
    marginRight: '98px',
    paddingLeft: '108px',
    transition: 'all 0.2s',
    boxSizing: 'content-box',
    maxWidth: 'calc(100% - 206px)',
  },
}));

export default useStyles;
