import { call, put, takeEvery } from 'redux-saga/effects';
import { getFindingsTable } from 'api-client/risc';
import {
  GET_FINDINGS_DATA_REQUEST,
  getFindingsDataFailure,
  getFindingsDataSuccess,
} from './findings.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadFindingsData({ payload }) {
  try {
    const { data } = yield call(getFindingsTable, payload.tenant, payload.filter);
    yield put(getFindingsDataSuccess(data));
  } catch (error) {
    yield put(getFindingsDataFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* findingsApi() {
  // @ts-expect-error
  yield takeEvery(GET_FINDINGS_DATA_REQUEST, loadFindingsData);
}

export default findingsApi;
